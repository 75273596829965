import React, { Component } from 'react'; //different
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import './style.css';
import Main from './main';
import Casino from './casino';
import NavMenu from './navmenu';
import Slot from './slot';
import Arcade from './arcade';
import Event from './event';
import Sport from './sport';
import Fising from './fising';
import Signin from './signin';
import Profile from './profile';
import Promotion from './promotionx';
import Login from './login';
import Logout from './logout';
import Statement from './statement';
import Deposit from './deposit';
import Withdraw from './withdraw';
import Affiliate from './affiliate';
import Banner from './banner';
import Loss from './loss';
import Linklistall from './linklistall';
import Linklistalldemo from './linklistaldemo';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import Freegame from './slotfree';
import Register from './register';
import io from "socket.io-client";
import MoblieMenu from "./mobilemenu";
import moment from 'moment';
import Spinwheels from './spinweel';
import $ from "jquery";
import Paoyingchub from './game/paoyingchub';
import { Flag } from '@mui/icons-material';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainsetting: [],
      rewardlist: [],
      activePage: 1,
      ThmeseV: null,
      checkboxcookie: false,
      isActive: false,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: true, // Indicates in progress state of login form
      pagecount: 1,
      username: null,
      settingwheel: "",
      settingcheckin: "",
      userid: null,
      phoneNumber: "",
      balance: 0,
      mainacc: null,
      pathname: "",
      qrcode: null,
      stateregis: 1,
      checkboxacc: 0,
      products_page_count: 1,
      loginstate: false,
      fullname: null,
      lname: null,
      link: "",
      alert: null,
      depositstate: 0,
      stateforget: 1,
      depositamount: 0,
      stateopterror: "",
      btnisActive: true,
      otpphone: "",
      token: window.localStorage.getItem("token"),
      accnumber: null,
      accnumbertxt: null,
      withdrawamount: 0,
      rankpoint: 0,
      changepassword: false,
      linelink: "",
      errortext: "",
      trueaccount: null,
      datas: null,
      otpphoneforget: "",
      affpercent: 0,
      userrecomend: "",
      historylist: [],
      useradvice: [],
      cashoutlist: [],
      tblnextrank: [],
      tblrankall: [],
      maxwithdrawstatus: 0,
      tblprofile: [],
      prodetail: [],
      oldpassword: "",
      linkaff: "",
      webnamex: "",
      inviteSet: "",
      truegiftlink: "",
      proderedeem: [],
      proderedeemlist: [],
      buttoncliam: false,
      limit: null,
      rankpic: "1.jpeg",
      rankname: null,
      rankob: null,
      cashoutSet: [],
      tblPopupMenu1: [],
      tblPopupMenu2: [],
      tblPopupMenu: null,
      termAndCondition: false,
      errorstatus: false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.forgetotpandregister = this.forgetotpandregister.bind(this);
  }
  copyCode = () => {
    navigator.clipboard
      .writeText(this.state.linkaff)
      .then(() => {
        setTimeout(function () {
        }, 3000);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  openAccout(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("js-profile-account-modal");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tabaccount");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }
  showClockRealTime = (datestart) => {
    // var days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];

    var months_th_mini = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค.",];
    var today = new Date(datestart);
    var endday = months_th_mini[today.getMonth()];

    return endday;
  }
  handleClick = async (e, price) => {
    if (price <= this.state.balance) {
      this.setState({
        withdrawamount: price
      });
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  }

  getpopup = () => {
    //  var token = localStorage.getItem('auth_token');
    //  if()
    // var decoded = jwt_decode(token);
    //  var Username = decoded.message.playerid;
    instance.post("/api/v1/popuplist", {
      System: Systems,
      Cat_ID: 0,
      page: 1,
      type: 1
    }).then(async (res) => {
      if (res.data.status === 200) {
        console.log(160, res.data.message.banklist);
        this.setState({
          tblPopupMenu1: res.data.message.tblPopupMenu1,
          tblPopupMenu: res.data.message.banklist,
          tblPopupMenu2: res.data.message.tblPopupMenu2,
        });



      }
    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });

  }
  checkpro = async (e, proid) => {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/checkpromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        console.log(209, res.data);
        if (res.data.type === 0) {
          this.setState({
            prodetail: res.data.message.tblpromotions[0],
          });
        }
        else if (res.data.type === 1) {
          this.setState({
            proderedeem: res.data.message.tblpromotionredeems,
            proderedeemlist: res.data.message.tblpromotionredeems,
          });
        }

      }
      else if (res.data.status === 201) {
        this.setState({
          prodetail: [],
          picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
        });

      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  redeempro = async (e, proid) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    // console.log(Username, proid);
    await instance.post("/api/v1/redeempromotion", {
      userid: Username,
      System: Systems,
      Proid: proid,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      //  console.log(res.data.status)
      if (res.data.status === 200) {
        document.getElementById("opendeposit").click()
        // await Swal.fire({
        //   title: 'success!',
        //   text: 'm',
        //   icon: 'success',
        //   confirmButtonText: 'ตกลง'
        // })
      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }
      else if (res.data.status === 204) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้สิทธวันนี้ครบแล้ว',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        e.preventDefault();
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  async recheccredit(evt) {

    evt.currentTarget.className += " fa-spin";
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    //this.rechecklogin(Username, token);
    this.setState({
      userid: decoded.message.playerid,
      username: decoded.message.playerid,
      firstname: decoded.message.firstname,
      lastname: decoded.message.lastname,
      loginstate: true,
    });
    try {
      // console.log(2);
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Function: 'recheccredit-App.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token)
          this.setState({
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fullname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
          var i, tablinks;
          tablinks = document.getElementsByClassName("btn btn-link -reload-btn p-0");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
          }
        }
        else if (res.data.status === 401) {
          //   console.log(res.data);
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      ////////console.log(ex);
    }
  }

  handleClickdeposit = async (e, price) => {
    this.setState({
      depositamount: price

    });
  }
  onChangexdep = event => {
    ////console.log(event.target.value);
    if (event.target !== undefined) {

      this.setState({ depositamount: event.target.value });
    }
  };

  onChangexgiftlink = event => {
    ////console.log(event.target.value);
    if (event.target !== undefined) {

      this.setState({ truegiftlink: event.target.value });
    }
  };

  checkpromotions = async (e, num) => {
    e.preventDefault();
  }


  confirmdeposittrue = async (e, num) => {
    e.preventDefault();

    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    if (token != null && this.state.btnisActive === true) {
      if (this.state.truegiftlink !== "") {
        this.setState({ statetrue: "โปรดรอซักครุ่กำลังดำเนินการ", btnisActive: false });
        Username = decoded.message.playerid;
        await instance.post("/api/v1/redeemtrue", {
          userid: Username,
          System: Systems,
          link: this.state.truegiftlink
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
          if (res.data.status == 200) {
            console.log(res.data.amountx);
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Success!',
              text: "เติมเงินสำเร็จจำนวน " + res.data.amountx + "  บาท ",
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status == 202) {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ลิ้งค์ของขวัญถูกใช้งานไปแล้วไม่สามารถไช้ได้',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status == 203) {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ลิงค์เติมเงิน ไม่ใช่ชื่อบัญชีของคุณ โปรดตรวจสอบ',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status == 205) {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ปิดระบบเติมเงินทรู รออัพเดท',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
          else {
            this.setState({ btnisActive: true, statetrue: "โปรดใส่ลิ้งค์ ของขวัญด้านล่าง ขั้นต่ำ 10 บาท" });
            await Swal.fire({
              title: 'Warning!',
              text: 'ทำรายการไม่สำเร็จหรือลิงค์ไม่สามารถใช้ได้ ',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (this.state.truegiftlink === "") {
        await Swal.fire({
          title: 'Warning!',
          text: 'คุณไม่ได้ไส่ลิ้งค์ของขวัญ ',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'กำลังดำเนินการโปรดรอซักครู่',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }
  }
  confirmdeposit = async (e, num) => {
    // console.log(493,num)
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    if (token != null) {
      await instance.post("/api/v1/getredeempromotion", {
        userid: Username,
        System: Systems,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        if (res.data.status === 200) {
          this.setState({
            proderedeem: res.data.message.tblpromotionredeems,
          });

        }
      }).catch(async (err) => {
      });
    }

    if (num === 0) {
      if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
        const { depositstate } = this.state
        //////console.log(depositstate, num);
        if (num === 0) {
          if (token != null) {
            Username = decoded.message.playerid;
            instance.post("/api/v1/getqrcode", {
              username: Username,
              System: Systems,
              amount: this.state.depositamount
            }, {
              headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
              //////console.log(res.data.picture)
              this.setState({
                mainacc: res.data.mainacc,
                qrcode: res.data.picture
              });
            });
          }

          this.setState({
            depositstate: 1
          });
        }
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else if (num === 1) {
      this.setState({
        depositstate: 1
      });
    }

    else if (num === 5) {
      document.getElementById("truemonney").click()
      //deposittruemoneyModal
    }
    else {
      this.setState({
        depositstate: 0
      });
    }



  }

  redeemrank = async (e, index) => {
    e.preventDefault();
    //console.log(index);

    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการเคลม Rank หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/userredeemrank", {
            User_id: Username,
            rankno: index.no,
            System: Systems,
            amount: index.claimamount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              const datas = res.data.message;
              this.calllisthistory(Username);
              this.checklogin();
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }
  async opencliamcashout(evt, cityName) {
    var token = localStorage.getItem('auth_token');
    var decoded = jwt_decode(token);
    var Username = decoded.message.playerid;
    await instance.post("/api/v1/cliamcashout", {
      userid: Username,
      System: Systems,
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(async (res) => {
      if (res.data.status === 200) {
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        this.rechecklogin(Username, token);
        await Swal.fire({
          title: 'Success!',
          text: 'ทำรายการรับเงินเรียบร้อย',
          icon: 'success',
          confirmButtonText: 'ตกลง'
        })

      }
      else if (res.data.status === 201) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }
      else if (res.data.status === 202) {
        await Swal.fire({
          title: 'error!',
          text: 'ไม่สามารถทำรายการได้',
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
        return false;
      }

    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });
  }

  resetpassword = async (e, type) => {
    e.preventDefault();
    const { phoneNumber, fname, password, oldpassword, passwordcf, checkboxacc, accnumbertxt } = this.state;
    console.log(type, password, oldpassword, passwordcf);
    var token = localStorage.getItem('auth_token');
    if (password === passwordcf) {
      var Username = null;
      var decoded = null;
      if (type === 1 && token != null) {
        decoded = jwt_decode(token);
        Username = decoded.message.playerid;
        instance.post("/api/v1/resetpassword", {
          username: Username,
          oldpass: oldpassword,
          System: Systems,
          newpass: password,
          type: type
        }, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'Success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'Success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (type === 2) {
        instance.post("/api/v1/resetpassword", {
          username: phoneNumber,
          oldpass: oldpassword,
          newpass: password,
          System: Systems,
          type: type
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
      else if (type === 3) {
        instance.post("/api/v1/resetpassword", {
          username: phoneNumber,
          oldpass: oldpassword,
          newpass: password,
          System: Systems,
          type: type
        }).then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'success!',
              text: 'เปลี่ยนพาสเวิดเรียบร้อย',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 201) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใส่รหัสเก่าผิดพลาด',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 203) {
            await Swal.fire({
              title: 'error!',
              text: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
          else if (res.data.status === 202) {
            await Swal.fire({
              title: 'error!',
              text: 'คุณใช้พาสเวิดเดิม',
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          }
        });
      }
    }
    else {
      await Swal.fire({
        title: 'error!',
        text: 'คุณใส่พาสเวิดไม่ตรงกัน',
        icon: 'error',
        confirmButtonText: 'ตกลง'
      })
    }
  }


  nextstep = async (e, num) => {
    e.preventDefault();
    const { stateregis, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state

    console.log(num);
    if (num === 1) {
      const check = await this.forgetotpandregister(8);
      console.log(1, phoneNumber.length, check.checked);
      if (phoneNumber.length === 10 && check.checked) {
        this.setState({
          stateregis: 2
        });
      }
      else {
        if (phoneNumber.length !== 10) {
          this.setState({
            stateopterror: "คุณใส่เบอร์ผิดพลาด"
          });
        }
        else if (!check.checked && check.status === 201) {
          this.setState({
            stateopterror: "เบอร์นี้มีคนใช้แล้ว"
          });
        }
        else {
          this.setState({
            stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
          });
        }

        document.getElementById("phoneNumber").style = "border-color: #ef4444;";
        document.getElementById("checkotpforgetphonenormal").style.display = "block";
      }
    }
    else if (num === 2) {

      if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "") {
        this.setState({
          stateregis: 3
        });
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน !!',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        //////console.log(2, stateregis, checkboxacc);
      }
    }
    else if (num === 3) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 4
      });
    }
    else if (num === 5) {
      var check = await this.forgetotpandregister(7);
      if (otpphone !== "" && check.checked) {
        this.setState({
          stateregis: 2
        });
      }
      else if (otpphone === "" || otpphone.length < 4) {
        this.setState({
          stateopterror: "คุณใส่ตัวเลขไม่ครบ"
        });
        document.getElementById("otpphone").style = "border-color: #ef4444;";
        document.getElementById("checkotp").style.display = "block";
      }

      else {
        this.setState({
          stateopterror: "รหัสผิดพลาด"
        });
        document.getElementById("otpphone").style = "border-color: #ef4444;";
        document.getElementById("checkotp").style.display = "block";
      }



    }
    else if (num === 11) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 1
      });
    }
    else if (num === 22) {
      //////console.log(1, stateregis, num);
      this.setState({
        stateregis: 2
      });
    }
  }

  forgetotpandregister = async (type) => {
    const { phoneNumber, otpphoneforget, otpphone } = this.state;
    var recheck = false;
    if (type === 4) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;
      });
    }
    else if (type === 5) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphoneforget,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    else if (type === 6) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;
      });
    }
    else if (type === 7) {
      await instance.post("/api/v1/resetpassword", {
        username: phoneNumber,
        pin: otpphone,
        System: Systems,
        type: type
      }).then(async (res) => {
        recheck = res.data.message;

      });
    }
    return recheck
  }

  test = async () => {
    await Swal.fire({
      title: 'Warning!',
      text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
      icon: 'warning',
      confirmButtonText: 'ตกลง'
    })
  }

  nextstepforget = async (e, num) => {
    e.preventDefault();
    console.log(num);
    const { phoneNumber, otpphoneforget } = this.state
    if (num === 1) {
      const check = await this.forgetotpandregister(4);
      if (phoneNumber.length === 10 && check.checked) {
        this.setState({
          stateforget: 2
        });
      }
      else {
        if (phoneNumber.length !== 10) {
          this.setState({
            stateopterror: "คุณใส่เบอร์ผิดพลาด"
          });
        }
        else if (!check.checked && check.status === 201) {
          this.setState({
            stateopterror: "ไม่มีเบอร์นี้ในระบบ"
          });
        }
        else {
          this.setState({
            stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
          });
        }
        document.getElementById("phoneNumberforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforgetphone").style.display = "block";
      }
    }
    else if (num === 2) {
      const check = await this.forgetotpandregister(5)
      console.log(check)
      if (otpphoneforget !== "" && check.checked) {
        this.setState({
          stateforget: 3
        });
      }
      else if (otpphoneforget === "" || otpphoneforget.length < 4) {
        this.setState({
          stateopterror: "คุณใส่ตัวเลขไม่ครบ"
        });
        document.getElementById("otpphoneforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforget").style.display = "block";
      }
      else {
        this.setState({
          stateopterror: "รหัสผิดพลาด"
        });
        document.getElementById("otpphoneforget").style = "border-color: #ef4444;";
        document.getElementById("checkotpforget").style.display = "block";
      }

    }
    else if (num === 11) {
      this.setState({
        stateforget: 1
      });
    }
  }

  submitwithdraw = async (e) => {
    //////console.log(this.state.withdrawamount, this.state.balance);
    if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
      if (parseFloat(this.state.withdrawamount) >= 100) {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
          var decoded = jwt_decode(token);
          var Username = decoded.message.playerid;
          instance.post("/api/v1/userwithdraw", {
            User_id: Username,
            System: Systems,
            amount: this.state.withdrawamount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              await Swal.fire({
                title: 'Success!',
                text: 'รายการถอนเรียบร้อย',
                icon: 'success',
                confirmButtonText: 'ตกลง'
              })
              window.location.reload(false);
            }
            else if (res.data.status === 206) {
              await Swal.fire({
                title: 'Success!',
                text: 'ทำรายการสำเร็จ คุณสามารถถอนได้ไม่เกิน ' + res.data.amountturnover + " บาทเนื่องจากคุณรับโบนัส เครดิตส่วนที่เหลือเราจะทำการตัดออก",
                icon: 'success',
                confirmButtonText: 'ตกลง'
              })
            }
            else if (res.data.status === 203) {
              await Swal.fire({
                title: 'Error!',
                text: 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท",
                icon: 'error',
                confirmButtonText: 'ตกลง'
              })
            }
          });
        }
        else {
          await Swal.fire({
            title: 'error!',
            text: 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
        }
      }
      else {
        await Swal.fire({
          title: 'Warning!',
          text: 'ขั้นต่ำในการถอน 100 บาท',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
      }
    }
    else {
      await Swal.fire({
        title: 'Warning!',
        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      })
    }

  }

  onChangex = event => {
    // console.log("event", event.target.name);


    if (event.target !== undefined) {
      let nam;
      let val;
      if (event.target.name === "otpphone") {
        document.getElementById("otpphone").style = "";
        document.getElementById("checkotp").style.display = "none";
      }
      else if (event.target.name === "phoneNumber") {
        document.getElementById("phoneNumberforget").style = "";
        document.getElementById("checkotpforgetphone").style.display = "none";
      }
      else if (event.target.name === "otpphoneforget") {
        document.getElementById("otpphoneforget").style = "";
        document.getElementById("checkotpforget").style.display = "none";
      }
      else if (event.target.name === "otpphoneforget") {
        document.getElementById("phoneNumber").style = "";
        document.getElementById("checkotpforgetphonenormal").style.display = "none";
      }

      if (event.target.name === undefined) {
        nam = event.name;
        val = event.value;
      } else {
        nam = event.target.name;
        val = event.target.value;
      }
      this.setState({ [nam]: val });
    }
  };

  logout = (e) => {
    ////////console.log(e);
    // window.location.href = '/logout';

  }

  openchangepassword = (e) => {
    e.preventDefault();
    const { changepassword } = this.state
    ////////console.log(changepassword);
    if (changepassword)
      this.setState({
        changepassword: false
      });
    else
      this.setState({
        changepassword: true
      });

  }

  cancelwithdraw = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการถอนเงินใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/userwithdrawcancel", {
            User_id: Username,
            tid: index.tid,
            System: Systems,
            amount: index.amount
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              const datas = res.data.message;
              this.calllisthistory(Username);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }

  cancelredeempromotion = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      Swal.fire({
        icon: 'warning',
        title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
        showCancelButton: true,
        confirmButtonText: 'Save',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await instance.post("/api/v1/usercancelpromotion", {
            User_id: Username,
            no: index.no,
            System: Systems,
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(async (res) => {
            if (res.data.status === 200) {
              this.checkpro(null, 999);
              Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
            }
            else {
              Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
            }
          });

        }
      })
    }

  }
  couponredeem = async (e, index) => {
    e.preventDefault();
    var token = localStorage.getItem('auth_token');
    if (token != null) {
      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      await instance.post("/api/v1/usercoupon", {
        User_id: Username,
        System: Systems,
        couponkey: this.state.couponkey,
        type: 1
      }).then(async (res) => {
        if (res.data.status === 200) {
          const datas = res.data.message;
          await Swal.fire({
            title: 'Success!',
            text: 'ดำเนินการใช้คูปองเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'ตกลง'
          })
        }
        else {
          await Swal.fire({
            title: 'Warning!',
            text: 'ไม่มี Key นี้ในระบบ',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      });
    }

  }

  async calllisthistory(userid) {
    await instance.post("/api/v1/userwithdrawlist", {
      userid: userid,
      Cat_ID: 0,
      System: Systems,
      page: 1,
      type: 1
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        //  //console.log(datas.withdrawlist);
        this.setState({
          historylist: datas.withdrawlist
        });

      }
      else {
        // this.CheckThisGoalError();
      }
    });

  }

  // async saveclick(linkid) {
  //   await instance.post("/api/v1/saveclick", {
  //     linkid: linkid,
  //     System: Systems,
  //   }).then((res) => {
  //   });

  // }
  async callgetthemesv(Systems) {
    await instance.post("/api/v1/getthemesv", {
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        document.body.setAttribute("data-theme", res.data.ThmeseV)
        this.setState({
          ThmeseV: res.data.ThmeseV
        });

      }
      else {
        // this.CheckThisGoalError();
      }
    });

  }
  async saveclickaff(linkid) {
    await instance.post("/api/v1/saveclickaff", {
      linkid: linkid,
      System: Systems,
    }).then((res) => {
      if (res.data.status == 200) {
        var date = new Date()
        this.setState({
          userrecomend: res.data.username,
          link: linkid
        });
        var username = res.data.username
        var object = { date, username }
        localStorage.setItem('aff', JSON.stringify(object))
      }
    }).catch(async (err) => {
    });

  }
  async componentDidMount() {
    await this.callgetthemesv(Systems);
    this.setState({
      pathname: window.location.pathname.replace("/", "").trim()
    });
    //  const endpoint = "https://betner.co:9000";
    const endpoint = "https://betner.co:9000";
    const socket = io.connect(endpoint);
    var roomid = Systems;
    var obj = { roomid }
    var json = JSON.stringify(obj);
    socket.on('connect', function (data) {
      var obj = { roomid }
      var json = JSON.stringify(obj);
      socket.emit('new', json);
    });
    const space = window.location.host
    //console.log(space);
    const search = window.location.href;
    var test = search.split("aff=")[1]
    //console.log(test.split("&")[0]);
    if (test != undefined) {
      this.saveclickaff(test.split("&")[0]);
    }

    var params = search.split("reg=")[1]
    //  console.log(params);
    if (params != undefined) {
      this.saveclick(params);
      this.setState({
        userrecomend: params.split("&")[0],
      });
    }


    var token = localStorage.getItem('register');
    if (token != null) {

      var codev = JSON.parse(token)
      console.log(1359, codev);
      var Username = codev.username;

      this.setState({
        userrecomend: Username,
      });
    }


    var token = localStorage.getItem('aff');
    if (token != null) {
      var codev = JSON.parse(token)
      console.log(1370, codev);
      var Username = codev.username;
      this.setState({
        userrecomend: Username,
      });
    }


    try {

      await instance.post("/api/v1/mainsetting", {
        System: Systems,
      }).then((res) => {
        if (res.data.status === 200) {


          const datas = res.data.message;
          // console.log( datas[7].value)
          var min = res.data.tblcatagoryx[0].limitdeposit;
          var max = res.data.tblcatagoryx[0].maxdeposit;
          var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
          var limit = { min, max }
          this.setState({
            maxwithdrawstatus: maxwithdrawstatus,
            linelink: datas[1].value,
            webnamex: datas[7].value,
            System: res.data.tblcatagoryx[0].System,
            cashoutSet: res.data.tblcashoutsetting[0],
            inviteSet: res.data.tblinvitedsetting[0],
            datas: datas,
            limit: limit,
          });
        }
        else {
          // this.CheckThisGoalError();
        }
      });
    }
    catch (ex) {
      console.log(ex);
    }

    await this.checklogin();
    // if (!this.state.checkboxcookie) {
    //   setTimeout(function () {
    //     document.getElementById("openpopup").click()
    //   }, 500);
    // }

    //await this.getwheel();    
    //await this.getcheckin();
    await this.getpopup();
  }

  onChange = async event => {

    if (event.target !== undefined) {
      let nam;
      let val;
      if (event.target.name === undefined) {
        nam = event.name;
        val = event.value;
      } else {
        nam = event.target.name;
        val = event.target.value;
      }
      if (nam === "withdrawamount") {

        if (this.state.maxwithdrawstatus === 1) {

          this.setState({ [nam]: this.state.balance });
          await Swal.fire({
            title: 'Warning!',
            text: 'บังคับถอนยอดทั้งหมด',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
        else if (Number(val) <= Number(this.state.balance)) {
          this.setState({ [nam]: val });
        }
        else {
          await Swal.fire({
            title: 'Warning!',
            text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
        }
      } else {
        this.setState({ [nam]: val });
      }

    }
  };

  async checkuseradvice() {
    instance.post("/api/v1/checkuseradvice", {
      User_id: this.state.username,
      page: 1,
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data.message.useradvicelist);
        this.setState({
          useradvice: res.data.message.useradvicelist
        });
      }
      else {
        // console.log(res.data);
        // this.CheckThisGoalError();
      }
    });
  }

  closemodal = async (e) => {

    console.log(1159, this.state.checkboxcookie);
    if (this.state.checkboxcookie) {
      var date = new Date()
      var checked = this.state.checkboxcookie
      var object = { date, checked }
      console.log(object);
      localStorage.setItem('cookie', JSON.stringify(object))
    }
  }

  async recheckbox() {
    var checked = false;
    if (this.state.checkboxcookie) checked = false;
    else checked = true;
    this.setState({
      checkboxcookie: checked,
    });

  }

  async checklogin() {

    var token = localStorage.getItem('auth_token');
    //console.log(token);
    if (token != null) {
      var cookie = localStorage.getItem('cookie');
      console.log(1188, cookie);
      if (cookie != 'null' && cookie != null) {
        var persons = JSON.parse(cookie);
        if (persons.checked != null) {
          this.setState({

            checkboxcookie: persons.checked,
          });
        }
      }

      var decoded = jwt_decode(token);
      var Username = decoded.message.playerid;
      // console.log(33);
      await this.rechecklogin(Username, token);
    }
    else {
      this.setState({
        loginstate: false,
      });
    }
  }

  redeem = (e) => {
    e.preventDefault();
    //this.checklogin();
    console.log('tag', this.state.loginstate);
    if (!this.state.loginstate) {
      var title = 'error'
      var text = ' คุณไม่ได้ทำการ Login'
      var object = { title, text }
      this.modalerror(object);
    }
    else {
      window.location.href = '/promotions';
    }
  }

  redeemlink = (e, link) => {
    e.preventDefault();
    //this.checklogin();
    console.log('tag', this.state.loginstate);
    if (!this.state.loginstate) {
      var title = 'error'
      var text = ' คุณไม่ได้ทำการ Login'
      var object = { title, text }
      this.modalerror(object);
    }
    else {
      window.location.href = link;
    }
  }


  async modalerror(object) {
    await Swal.fire({
      title: object.title + "!",
      text: object.text,
      icon: object.title,
      confirmButtonText: 'ตกลง'
    })
  }
  validatergisterForm = async (e) => {
    e.preventDefault();
    const { phoneNumber, fname, password, passwordcf, checkboxacc, accnumbertxt } = this.state;

    //////console.log(checkboxacc);
    //////////console.log(this.state)
    if (password.length < 6) {
      ////////console.log(password);
      var title = 'Error!'
      var text = 'Password คุณไม่ครบ 6 ตัวโปรดตรวจสอบ'
      var object = { title, text }
      this.modalerror(object);
      // this.CheckThisGoalError(object);
    }

    else if (phoneNumber === "") {

      title = 'Error!'
      text = 'คุณไม่ทำการใส่ เบอร์โทรศัพท์'
      object = { title, text }
      this.modalerror(object);

    }
    else if (accnumbertxt === "") {
      title = 'Error!'
      text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
      object = { title, text }
      this.modalerror(object);

    }
    else if (fname === "") {

      title = 'Error!'
      text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
      object = { title, text }
      this.modalerror(object);

    }
    else if (checkboxacc === 0) {
      title = 'Error!'
      text = 'คุณยังไม่ได้เลือกธนาคาร'
      object = { title, text }
      this.modalerror(object);
    }
    else if (password !== passwordcf) {
      title = 'Error!'
      text = 'คุณไส่พาสเวิดไม่ตรงกัน'
      object = { title, text }
      this.modalerror(object);
    }

    else {
      instance.post("/api/v1/register", {
        User_id: phoneNumber,
        name: fname,
        password: password,
        accbank: checkboxacc,
        accno: accnumbertxt,
        System: Systems,
        Useradvise: this.state.userrecomend
      })
        .then(async (res) => {
          if (res.data.status === 200) {
            await Swal.fire({
              title: 'Success!',
              text: 'ทำการสมัครเรียบร้อยโปรด Log in อีกครั้ง',
              icon: 'success',
              confirmButtonText: 'ตกลง'
            })
            window.location.href = '/';
          }
          else if (res.data.status === 202) {
            this.setState({
              errorstatus: 1,
              errortext: 'Username นี้ทำการสมัครไปแล้ว'
            });
          }
          else {
            this.setState({
              errorstatus: 1,
              errortext: 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
            });
          }
        });
    }


  }
  // async rechecklogin(Username, token) {
  //   try {
  //     // console.log(this.state.System);
  //     //console.log(3);
  //     const linkaff = this.state.webname + "/register?affuser=" + Username;
  //     await instance.post("/api/v1/checkpoint", {
  //       userid: Username,
  //       System: Systems,
  //       Function: 'rechecklogin-App.js'
  //     }, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     }).then(async (res) => {
  //       // //console.log(res.data);
  //       if (res.data.status === 200) {
  //         this.calllisthistory(Username);
  //         //console.log(443, res.data);
  //         localStorage.setItem('auth_token', res.data.token)
  //         var rankall = res.data.tblrankall;
  //         const found = rankall.find(obj => {
  //           return obj.checked === 1;
  //         });
  //         this.setState({
  //           tblrankall: res.data.tblrankall,
  //           linkaff: linkaff,
  //           buttoncliam: (found === undefined) ? true : false,
  //           tblnextrank: res.data.tblnextrank,
  //           rankpic: res.data.tblrank.image,
  //           rankname: res.data.tblrank.name,
  //           rankob: res.data.tblrank,
  //           tblprofile: res.data.message,
  //           rankpoint: res.data.rankpoint,
  //           accnumber: res.data.message.accnumber,
  //           userid: res.data.message.member_id,
  //           username: res.data.message.playerid,
  //           fullname: res.data.message.firstname,
  //           point: res.data.message.Point,
  //           balance: res.data.databalance,
  //           loginstate: true,
  //         });
  //       }
  //       else if (res.data.status === 401) {
  //         localStorage.clear();
  //         await Swal.fire({
  //           title: 'Warning!',
  //           text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
  //           icon: 'warning',
  //           confirmButtonText: 'ตกลง'
  //         })
  //         window.location.href = '/';
  //       }

  //     }).catch(async (err) => {
  //       // console.log(err);
  //       if (err.response.status === 401 || err.response.status === 403) {
  //         localStorage.clear();
  //         await Swal.fire({
  //           title: 'Warning!',
  //           text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
  //           icon: 'warning',
  //           confirmButtonText: 'ตกลง'
  //         })
  //         window.location.href = '/';
  //       }
  //     });
  //   }
  //   catch (ex) {
  //     ////////console.log(ex);
  //   }

  // }

  rechecklogin = async (Username, token) => {
    try {

      //const { webnamex } = this.state
      console.log(1406, Username);
      //console.log(3);
      const search = window.location.href;
      const paramx = new URLSearchParams(search);
      // const space = search.split("/")
      const space = window.location.host
      var userhash = md5(Username);
      //  const linkaff = "https://pgline88.com/?register=" + userhash;
      const linkaff = "https://" + space + "/register?reg=" + userhash;
      await instance.post("/api/v1/checkpoint", {
        userid: Username,
        System: Systems,
        Update: userhash,
        Function: 'rechecklogin-App.js'
      }, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(async (res) => {
        console.log(1751, res.data);
        if (res.data.status === 200) {


          this.calllisthistory(Username);
          console.log(443, res.data);
          localStorage.setItem('auth_token', res.data.token)
          var rankall = res.data.tblrankall;
          const found = rankall.find(obj => {
            return obj.checked === 1;
          });

          this.setState({
            tblrankall: res.data.tblrankall,
            linkaff: linkaff,
            buttoncliam: (found === undefined) ? true : false,
            tblnextrank: res.data.tblnextrank,
            rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
            rankname: res.data.tblrank.name,
            rankob: res.data.tblrank,
            tblprofile: res.data.message,
            rankpoint: res.data.rankpoint,
            accnumber: res.data.message.accnumber,
            userid: res.data.message.member_id,
            username: res.data.message.playerid,
            fullname: res.data.message.firstname,
            point: res.data.message.Point,
            balance: res.data.databalance,
            loginstate: true,
          });
        }
        else if (res.data.status === 401) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }

      }).catch(async (err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          await Swal.fire({
            title: 'Warning!',
            text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
            icon: 'warning',
            confirmButtonText: 'ตกลง'
          })
          window.location.href = '/';
        }
      });
    }
    catch (ex) {
      ////////console.log(ex);
    }

  }


  render() {
    //////////console.log(this.state)this.state.userrecomend
    const pathname = this.state.pathname
    const { loginstate, cashoutSet, stateopterror, changepassword, username, balance, accnumber, fullname, mainacc, limit, rankob, rankpoint, tblnextrank, userrecomend, linkaff, tblprofile, proderedeem, proderedeemlist, prodetail } = this.state

    let stype = false;
    if (pathname !== "event" || pathname !== "loss" || pathname !== "promotion" || pathname !== "affiliate" || pathname !== "deposit" || pathname !== "withdraw" || pathname == "signin" || pathname !== "register") {
      stype = true
    }
    var accnumberx;
    var limits = limit
    if (accnumber != null) {
      accnumberx = accnumber[0];
    }

    return (
      < >

        <div className="-contact-wrapper row m-0 ">

          <BrowserRouter >
            <Routes>
              <Route path="/*" element={<Main />} />
              {/* <Route path="/slot" element={<Slot />} />
              <Route path="/sport" element={<Sport />} /> */}
              {/* <Route path="/loss" element={<Loss />} /> */}
              {/* <Route path="/deposit" element={<Deposit />} /> */}
              <Route path="/signin" element={<Signin />} />
              {/* <Route path="/profile" element={<Profile />} /> */}
              <Route path="/arcade" element={<Arcade />} />
              {/* <Route path="/event" element={<Event />} /> */}
              <Route path="/statement" element={<Statement />} />
              {/* <Route path="/fishing" element={<Fising />} /> */}
              {/* <Route path="/withdraw" element={<Withdraw />} /> */}
              {/* <Route path="/promotion" element={<Promotion />} /> */}
              <Route path="/freegame" element={<Freegame />} />
              {/* <Route path="/listgameall/*" element={<Linklistall />} /> */}
              <Route path="/listgamealldemo/*" element={<Linklistalldemo />} />
              <Route path="/logout" element={<Logout />} />
              {/* <Route path="/affiliate/overview" element={<Affiliate />} /> */}
              <Route path="/register/*" element={<Register />} />
              <Route path='/Paoyingchub' element={<Promotion />} />
            </Routes>
          </BrowserRouter>

       
          <div className="x-modal modal -alert-modal"
            id="alertModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            data-loading-container=".js-modal-content"
            data-ajax-modal-always-reload="true"
            data-animatable="fadeInRight"
            data-delay={700}
            data-dismiss-alert="true"
          >
            <div className="modal-dialog -modal-size  " role="document">
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-body -modal-body">
                  <div className="d-flex -alert-body">
                    <div className="text-center mr-3 -alert-body-wrapper">
                      <img
                        src="/build/web/igame-index-lobby-wm/img/ic-alert-success.png"
                        alt="SUCCESS"
                        className="-img-alert js-ic-success img-fluid lazyload"
                      />
                      <img
                        src="/build/web/igame-index-lobby-wm/img/ic-alert-failed.png"
                        alt="FAIL"
                        className="-img-alert js-ic-fail img-fluid lazyload"
                      />
                    </div>
                    <div className="my-auto js-modal-content -title" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal "
            id="loginModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            data-loading-container=".js-modal-content"
            data-ajax-modal-always-reload="true"

          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -dialog-in-tab"
              role="document"

            >
              <div className="modal-content -modal-content" >
                <button
                  type="button"
                  className="close f-1 -in-tab"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="x-modal-account-security-tabs">
                  <button
                    type="button"
                    data-toggle="modal"
                    className="-btn -register js-modal-account-security-tab-button "
                    data-modal-target="#registerModal"
                  >
                    สมัครสมาชิก
                  </button>
                  <button
                    type="button"
                    data-toggle="modal"
                    className="-btn -login js-modal-account-security-tab-button -active"
                    data-modal-target="#loginModal"
                  >
                    เข้าสู่ระบบ
                  </button>
                </div>
                <div className="modal-body -modal-body">
                  <Login />
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal "
            id="registerModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            data-container="#registerModal"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable "
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 -in-tab"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="x-modal-account-security-tabs">
                  <button
                    type="button"
                    className="-btn -register js-modal-account-security-tab-button -active"
                    data-modal-target="#registerModal"
                  >
                    สมัครสมาชิก
                  </button>
                  <button
                    type="button"
                    className="-btn -login js-modal-account-security-tab-button "
                    data-modal-target="#loginModal"
                  >
                    เข้าสู่ระบบ
                  </button>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-form-register -register mt-0">
                    <div className="row -animatable-container">
                      <div className="col order-1 text-center pr-lg-0 mx-auto js-slide-term-and-condition-content x-slide-left-content x-slide-left-content-term -hide">
                        <h3 className="x-title-modal mx-auto text-center ">
                          Term and condition
                        </h3>
                        <div className="-fake-inner-body">
                          <div className="-term-and-condition-content js-term-and-condition">
                            <div className="x-term-and-condition">
                              <div className="-block-content-term-and-condition -register-modal">
                                <div className="-inner-wrapper">
                                  <h1 className="f-4">ข้อตกลงในการใช้งาน</h1>
                                  <ul className="list-unstyled -detail">
                                    <li>
                                      สมาชิกต้องกรอกข้อมูลจริงให้สมบูรณ์และสามารถติดต่อได้
                                      เพื่อเปิดบัญชี Secret789-VIP Casino
                                      (บริษัทจำกัดคนละบัญชีเท่านั้น)
                                    </li>
                                    <li>
                                      ชื่อ-นามสกุล กับ
                                      ชื่อในข้อมูลธนาคารที่ให้สำหรับการฝาก/ถอน
                                      ต้องตรงกัน
                                      (บริษัทอนุมัติเฉพาะรายการที่ข้อมูลตรงกับข้อมูลสมาชิกเท่านั้น
                                      หากมีข้อผิดพลาดจากสมาชิกบริษัทจะไม่รับผิดชอบใดๆ)
                                    </li>
                                    <li>
                                      ทุกข้อเสนอจำกัดสำหรับหนึ่ง บุคคล, ชื่อ หรือ สกุล ,
                                      ที่อยุ่ , อีเมล์ , เบอร์โทรศัพท์, บัญชีธนาคาร , IP
                                      แอดเดรส เดียวเท่านั้น (บริษัทมีระบบตรวจสอบ
                                      การใช้ข้อมูล การเข้าใช้ ที่ซ้ำซ้อนกัน)
                                    </li>
                                    <li>
                                      สมาชิกที่ต้องการเปลี่ยนแปลงข้อมูล
                                      จะต้องไม่ติดกิจกรรมใดๆ และเคยฝากเงินแล้วเท่านั้น
                                    </li>
                                    <li>
                                      สมาชิกต้องวางเดิมพัน จึงจะสามารถถอนเงินได้ (
                                      ไม่สามารถฝาก และถอนเงินทันทีโดยที่ไม่วางเดิมพัน )
                                    </li>
                                    <li>
                                      ในกรณีตรวจพบว่าสมาชิก ฝาก/ถอน ผิดปกติเพื่อก่อกวน
                                      เอาเปรียบบริษัท หรือคาดว่าเป็นมิจฉาชีพ
                                      ทีมงานขอสงวนสิทธิ์ในการระงับยูสเซอร์ตรวจสอบ
                                      และตัดสิน
                                    </li>
                                    <li>
                                      ในกรณีตรวจพบว่าท่านสมาชิกมีการละเมิดข้อกำหนดเงื่อนไข
                                      หรือมีการกระทำผิดกติกาในการเข้าร่วมโปรโมชั่นเพื่อให้ได้มาซึ่งเครดิต,
                                      โบนัสพิเศษ, เทิร์นโอเวอร์ ในทางทุจจริต
                                      ทางเราขอสงวนสิทธิ์ในการระงับใช้บัญชีนั้นๆ ทันที
                                      และเครดิตที่ได้มาไม่สามารถถอนได้
                                    </li>
                                    <li>
                                      บริษัทขอสงวนสิทธิ์ในการแก้ไขหรือ
                                      ยกเลิกโปรโมชั่นสำหรับท่านสมาชิก
                                      ได้โดยอัพเดทหน้าเว็บไซต์
                                      และไม่ต้องแจ้งให้ทราบล่วงหน้า
                                    </li>
                                    <li>
                                      บริษัทขอสงวนสิทธิ์โดยใช้ดุลยพินิจแต่เพียงผู้เดียวในการทำให้เงินรางวัลเป็นโมฆะและริบยอดเงินใดก็ตามในบัญชีการเดิมพันของคุณ
                                      ในการสิ้นสุดข้อตกลงและ/หรือระงับการให้บริการ/ปิดการใช้งานบัญชี
                                    </li>
                                    <li>
                                      หากเราระบุได้ว่าคุณมีบัญชีกับเรามากกว่าหนึ่งบัญชี
                                    </li>
                                    <li>หากคุณกำลังละเมิดข้อกำหนดใดๆ ของข้อตกลงนี้</li>
                                    <li>
                                      หาก
                                      บริษัททราบว่าคุณได้วางเดิมพันกับเว็บไซต์วางเดิมพันออนไลน์หรือใช้บริการใดก็ตามและถูกสงสัยว่าได้ฉ้อโกง
                                      สมรู้ร่วมคิด
                                      หรือกิจกรรมที่ไม่เหมาะสมหรือมิชอบด้วยกฎหมาย
                                    </li>
                                    <li>
                                      หาก
                                      คุณไม่สามารถจัดเตรียมข้อมูลการยืนยันตัวตนตามที่ร้องขอ
                                    </li>
                                    <li>
                                      หากบริษัท ไม่สามารถทำการตรวจสอบ
                                      หรือข้อมูลที่ท่านให้มานั้นไม่ถูกต้อง, ข้อมูลเท็จ
                                      หรือข้อมูลไม่สมบูรณ์
                                      ทางเราขอสงวนสิทธิ์ที่จะทำการปฏิเสธโดยไม่มีการแจ้งให้ทราบล่วงหน้า
                                      หรือไม่รับผิดชอบใดๆในบัญชีของท่าน
                                    </li>
                                    <li>
                                      หาก
                                      คุณฝากเงินด้วยเงินที่ได้มาด้วยการทุจริตหรือมิชอบด้วยกฎหมายหรือได้มาอย่างไม่ถูกต้อง
                                    </li>
                                    <li>
                                      หากบริษัท
                                      สงสัยว่าบัญชีของท่านมีความเกี่ยวข้องกับการฉ้อโกงหรือการกระทำที่เป็นทุจริต
                                    </li>
                                    <li>
                                      หากบริษัท สงสัยว่าท่าน
                                      หรือสมรู้ร่วมคิดกับบุคคลอื่นๆ
                                      เพื่อที่จะทำการอย่างใดอย่างหนึ่ง
                                      หรือพยายามฉ้อโกงทางเว็บ
                                    </li>
                                    <li>
                                      หากบริษัท ได้รับแจ้งว่าท่านได้มีการปลอม
                                      หรือแทรกแซง
                                      หรือดำเนินการขั้นตอนเพื่อทำการปกปิดหรือแทรกแซงในทางใด
                                      ๆ ในเรื่องของ IP
                                      ในอุปกรณ์ที่ใช้ในการเข้าถึงเว็บไซต์ เช่น Virtual
                                      Private Network "VPN"
                                    </li>
                                    <li>
                                      หากคุณได้มีการใช้โปรแกรม VPN หรือวิธีการใดๆ
                                      ที่พยายามจะปลอมแปลง หรือซ่อนตัวตนที่แท้จริงของท่าน
                                      หรือการตรวจสอบตามขอบเขตอำนาจด้านการพนัน
                                    </li>
                                  </ul>
                                  <b>
                                    **บริษัท Secret789-VIP Casino
                                    เป็นผู้ตัดสินเพียงผู้เดียวและคำตัดสินใจถือเป็นที่สิ้นสุด**
                                  </b>
                                </div>
                              </div>
                              <div className="text-center d-lg-none">
                                <a
                                  href="#close-term-and-condition"
                                  className="js-get-term-and-condition btn  btn-primary my-0 my-lg-3"
                                >
                                  <span>ย้อนกลับ</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-animatable="fadeInRegister"
                        data-offset={0}
                        className="col order-lg-2 order-0 -form  fadeInRegister"
                      >
                        <div className="x-modal-separator-container ">
                          <div className="-top " style={{ display: this.state.stateregis === 1 ? "" : "none" }}>
                            <h3 className="x-title-modal mx-auto text-center ">
                              กรอกเบอร์โทรศัพท์
                            </h3>
                            <div className="-fake-inner-body">
                              <div className="x-step-register -v2">
                                <div className="px-0 m-auto -container-wrapper">
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                </div>
                              </div>
                              <div >
                                <div className="-x-input-icon mb-3 text-center">
                                  <img
                                    src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                    className="-icon"
                                    alt="SA Casino phone icon"
                                    width={10}
                                    height={14}
                                  />
                                  <input
                                    onChange={this.onChangex}
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    required="required"
                                    pattern="[0-9]*"
                                    className="x-form-control form-control"
                                    placeholder="กรุณากรอกเบอร์โทรศัพท์"
                                    autoFocus="autoFocus"
                                    autoComplete="on"
                                    inputMode="text"
                                  />
                                  <div id="checkotpforgetphonenormal" className="invalid-feedback ">
                                    <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                  </div>
                                </div>
                                <h3 className="x-title-modal mx-auto text-center " style={{ marginBottom: "15px" }}>
                                  ผู้แนะนำ(ไม่ไส่ก็ได้)
                                </h3>
                                <div className="-x-input-icon mb-3 text-center">
                                  <img
                                    src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png"
                                    className="-icon"
                                    alt="SA Casino phone icon"
                                    width={10}
                                    height={14}
                                  />
                                  <input
                                    onChange={this.onChangex}
                                    readOnly={true}
                                    style={{ color: "black", background: "transparent" }}
                                    type="text"
                                    id="userrecomend"
                                    name="userrecomend"
                                    required="required"
                                    value={userrecomend}
                                    className="x-form-control form-control"
                                    placeholder="ผู้แนะนำ"
                                    autoFocus="autoFocus"
                                    autoComplete="on"
                                    inputMode="text"
                                  />
                                </div>
                                <div className="text-center">
                                  <button
                                    onClick={(e) => {
                                      this.nextstep(e, 1)
                                    }}
                                    type="submitx"
                                    className="btn   btn-primary mt-lg-3 mt-0"
                                    disabled=""
                                  >
                                    ต่อไป
                                  </button>
                                </div>
                                <input
                                  type="hidden"
                                  id="request_otp__token"
                                  name="request_otp[_token]"
                                  defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="-top " style={{ display: this.state.stateregis === 5 ? "" : "none" }}>
                            <h3 className="x-title-modal mx-auto text-center ">
                              ยืนยัน OTP
                            </h3>
                            <div className="-img-container ">
                              <picture>
                                <source
                                  type="image/webp"
                                  srcSet="https://cdn.javisx.com/trixie/build/web/register-icon-otp-v2.webp"
                                />
                                <source
                                  type="image/png?v=5"
                                  srcSet="https://cdn.javisx.com/trixie/build/web/register-icon-otp-v2.webp"
                                />
                                <img
                                  alt="กรอกรหัส OTP เพื่อยืนยันตัวตน"
                                  className="img-fluid -ic-otp"
                                  width={150}
                                  height={150}
                                  src="https://cdn.javisx.com/trixie/build/web/register-icon-otp-v2.webp"
                                />
                              </picture>
                            </div>

                            <div className="-fake-inner-body">
                              <div className="x-step-register -v2">
                                <div className="px-0 m-auto -container-wrapper">
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 5 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                </div>
                              </div>
                              <div >
                                <div className="-x-input-icon mb-3 text-center">
                                  <img
                                    src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                                    className="-icon"
                                    alt="SA Casino phone icon"
                                    width={10}
                                    height={14}
                                  />
                                  <input
                                    onChange={this.onChangex}
                                    type="text"
                                    id="otpphone"
                                    name="otpphone"
                                    required="required"
                                    pattern="[0-9]*"
                                    maxLength="4"
                                    className="x-form-control form-control"
                                    placeholder="กรุณากรอก OTP 4 หลัก"
                                    autoFocus="autoFocus"
                                    autoComplete="on"
                                    inputMode="text"
                                  />
                                  <div id="checkotp" className="invalid-feedback ">
                                    <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <a href="#0" style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      this.nextstep(e, 11)
                                    }} className="text-muted mt-3">
                                    ยกเลิก
                                  </a>
                                </div>
                                <div className="text-center">

                                  <button
                                    onClick={(e) => {
                                      this.nextstep(e, 5)
                                    }}
                                    type="submitx"
                                    className="btn   btn-primary mt-lg-3 mt-0"
                                    disabled=""
                                  >
                                    ต่อไป
                                  </button>
                                </div>
                                <input
                                  type="hidden"
                                  id="request_otp__token"
                                  name="request_otp[_token]"
                                  defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="-top " style={{ display: this.state.stateregis === 2 ? "" : "none" }}>
                            <h3 className="x-title-modal mx-auto text-center ">
                              กรอกเลขที่บัญชี
                            </h3>
                            <div className="-x-input-icon mb-3 text-center">
                              <div
                                data-animatable="fadeInModal"
                                className="-animatable-container animated fadeInModal"
                              >
                                <div className="text-center -img-container">
                                  <div className="-title mt-3">กรุณาเลือกบัญชีธนาคารของท่าน</div>
                                </div>
                                <div className="x-step-register -v2">
                                  <div className="px-0 m-auto -container-wrapper">
                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                    <div className={"-step-box-outer" + (this.state.stateregis !== 2 ? "" : " step-active")}></div>
                                    <div className={"-step-box-outer" + (this.state.stateregis !== 1 ? "" : " step-active")}></div>
                                  </div>
                                </div>
                                <div id="card-bank-info">
                                  <div className="text-center">
                                    <div className="my-3 js-bank-select-container">
                                      <div className="x-bank-choices-type ">
                                        <div className="-outer-wrapper" id='checkboxacc' onChange={this.onChangex}>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-51692356943"
                                            name="checkboxacc"
                                            defaultValue={5}
                                          />
                                          <label className="-label" htmlFor="bank-acc-51692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/84c0f97b9a4a02bd519f6256da291d06.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-61692356943"
                                            name="checkboxacc"
                                            defaultValue={6}
                                            data-gtm-form-interact-field-id={6}
                                          />
                                          <label className="-label" htmlFor="bank-acc-61692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/75cf7484ae422a0d3897e82556cb04fd.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-71692356943"
                                            name="checkboxacc"
                                            defaultValue={7}
                                          />
                                          <label className="-label" htmlFor="bank-acc-71692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/ffcda61156271aa5d354b4d052590a14.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-81692356943"
                                            name="checkboxacc"
                                            defaultValue={8}
                                          />
                                          <label className="-label" htmlFor="bank-acc-81692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/4982427c6801f5e172ab1205a9166e2f.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-91692356943"
                                            name="checkboxacc"
                                            defaultValue={9}
                                          />
                                          <label className="-label" htmlFor="bank-acc-91692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/62cdaab59a028355f43825a426c41b94.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-111692356943"
                                            name="checkboxacc"
                                            defaultValue={11}
                                          />
                                          <label className="-label" htmlFor="bank-acc-111692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/6220cfdee8ca83b5533e6d5ac87b778b.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-121692356943"
                                            name="checkboxacc"
                                            defaultValue={12}
                                          />
                                          <label className="-label" htmlFor="bank-acc-121692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/a3671c1989ca4935f046b184b98b28d1.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-141692356943"
                                            name="checkboxacc"
                                            defaultValue={14}
                                          />
                                          <label className="-label" htmlFor="bank-acc-141692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/de6513eee5e2d90848de36a1be081ef5.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-151692356943"
                                            name="checkboxacc"
                                            defaultValue={15}
                                          />
                                          <label className="-label" htmlFor="bank-acc-151692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/4320a4686226cba7c35cfdbbe25a0e95.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-161692356943"
                                            name="checkboxacc"
                                            defaultValue={16}
                                          />
                                          <label className="-label" htmlFor="bank-acc-161692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/48e5e7cc029492341f34b7ce38926edd.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-171692356943"
                                            name="checkboxacc"
                                            defaultValue={17}
                                          />
                                          <label className="-label" htmlFor="bank-acc-171692356943">
                                            <img
                                              className="-logo"
                                              src="/media/cache/strip/201910/a68c3e5add8754e2c0e986c408c08c24.png"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                          <input
                                            type="radio"
                                            className="-input-radio"
                                            id="bank-acc-991692356943"
                                            name="checkboxacc"
                                            defaultValue={99}
                                          />
                                          <label className="-label" htmlFor="bank-acc-991692356943">
                                            <img
                                              className="-logo"
                                              src="https://yt3.googleusercontent.com/aWp38-LVqL_fztxB3VkBaYHj6otE3dmCq-QMhEJUdRvDQbn1xU5gvU9CrB15x6-aWbLA9oQ1CQ=s900-c-k-c0x00ffffff-no-rj"
                                              alt=""
                                            />
                                            <i className="fas fa-check" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="js-bank-number-and-name-container position-relative">
                                      <div className="x-bank-info-validate-box -v2">
                                        <div className="-content-validate">
                                          โปรดตรวจสอบ ชื่อ และ เลขบัญชี !!
                                        </div>
                                        <div className="-detail-validate">
                                          หากไม่ถูกต้อง จะไม่สามารถแก้ไข หรือ ฝากถอนได้
                                        </div>
                                        <i className="fas fa-arrow-right" />
                                      </div>
                                      <div className="-x-input-icon mb-3 flex-column">
                                        <img
                                          className="-icon"
                                          alt="กระเป๋าเงิน ฝากถอน ออโต้"
                                          width={15}
                                          height={15}
                                          src="/build/web/igame-index-lobby-wm/img/ic-library-book-v2.png?v=5"
                                        />
                                        <input
                                          type="text"
                                          onChange={this.onChangex}
                                          id="accnumbertxt"
                                          name="accnumbertxt"
                                          required="required"
                                          pattern="[0-9]*"
                                          className="x-form-control form-control"
                                          placeholder="กรอกเลขบัญชี"
                                          autoFocus="autoFocus"
                                          inputMode="text"
                                        />
                                      </div>
                                      <div className="-x-input-icon mb-3 flex-column">
                                        <img
                                          className="-icon"
                                          alt="รองรับการเข้าใช้งานหลายผู้เล่น 24 ชั่วโมง"
                                          width={15}
                                          height={15}
                                          src="/build/web/igame-index-lobby-wm/img/ic-user-v2.png?v=5"
                                        />
                                        <input
                                          type="text"
                                          onChange={this.onChangex}
                                          id="fname"
                                          name="fname"
                                          required="required"
                                          className="x-form-control form-control"
                                          placeholder="กรอก ชื่อ - นามสกุล (ภาษาไทยเท่านั้น)"
                                        />
                                      </div>
                                      <div className="-text-hint col-sm-10 m-auto d-block d-lg-none">
                                        กรอกข้อมูลให้ครบถ้วนและถูกต้องมิฉะนั้นท่านจะไม่สามารถถอนเงินได้
                                      </div>
                                    </div>
                                    <a
                                      href="#0"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        this.nextstep(e, 11)
                                      }} className="text-muted mt-3">
                                      ยกเลิก
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <input
                                type="hidden"
                                id="customer_bank_account__token"
                                name="customer_bank_account[_token]"
                                defaultValue="EtuWE7npKiT8yTfHa4RNu7uFMEY1cTFVfBKaSda8kGc"
                              />

                            </div>
                            {/* <div className="m-auto -term-and-condition-check-box">
                            <div className="x-checkbox-primary d-flex justify-content-center mt-3">
                              <div className="form-check" onChange={(e) => { this.onChangex(e) }}>
                                <input
                                  type="checkbox"
                                  id="termAndCondition"
                                  name="termAndCondition"
                                  className="x-form-control js-term-check-box form-check-input"
                                  defaultValue={1}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="request_otp_termAndCondition"
                                >
                                </label>
                              </div>
                              <span className="x-text-with-link-component">
                                <label
                                  className="-text-message mt-1"
                                  htmlFor="request_otp_termAndCondition"
                                >
                                  ยอมรับเงื่อนไข
                                </label>
                                <a
                                  href="#term-and-condition"
                                  className="-link-message js-get-term-and-condition"
                                  target="_self"
                                  rel="noopener noreferrer"
                                >
                                  <u>Term &amp; Condition</u>
                                </a>
                              </span>
                            </div>
                          </div> */}
                            <div className="text-center">
                              <button
                                onClick={(e) => {
                                  this.nextstep(e, 2)
                                }}
                                type="submitxa"
                                className="btn   btn-primary mt-lg-3 mt-0"
                                disabled=""
                              >
                                ต่อไป
                              </button>
                            </div>
                            <input
                              type="hidden"
                              id="request_otp__token"
                              name="request_otp[_token]"
                              defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                            />
                          </div>
                          <div className="-top " style={{ display: this.state.stateregis === 3 ? "" : "none" }}>
                            <h3 className="x-title-modal mx-auto text-center ">
                              กรุณา Confirm Password
                            </h3>
                            <div className="-fake-inner-body">
                              <div className="x-step-register -v2">
                                <div className="px-0 m-auto -container-wrapper">
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                  <div className={"-step-box-outer" + (this.state.stateregis !== 3 ? "" : " step-active")}></div>
                                </div>
                              </div>
                              <div >
                                <div className="text-center">

                                  <div className="-x-input-icon mb-3 text-center">
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                      className="-icon"
                                      alt="SA Casino phone icon"
                                      width={10}
                                      height={14}
                                    />
                                    <input
                                      onChange={this.onChangex}
                                      type="password"
                                      id="password"
                                      name="password"
                                      required="required"
                                      className="x-form-control form-control"
                                      placeholder="กรุณารหัสผ่าน"
                                      autoFocus="autoFocus"
                                      autoComplete="on"
                                      inputMode="text"
                                    />
                                  </div>
                                  <div className="-x-input-icon mb-3 text-center">
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                      className="-icon"
                                      alt="SA Casino phone icon"
                                      width={10}
                                      height={14}
                                    />
                                    <input
                                      onChange={this.onChangex}
                                      type="password"
                                      id="passwordcf"
                                      name="passwordcf"
                                      required="required"
                                      className="x-form-control form-control"
                                      placeholder="กรุณารหัสผ่าน"
                                      autoFocus="autoFocus"
                                      autoComplete="on"
                                      inputMode="text"
                                    />
                                    <div className="mt-3 -spacer  text-center" style={{ display: (this.state.errorstatus ? "" : "none") }}>
                                      <div className="x-danger-zone -v2  text-center">
                                        <span>{this.state.errortext}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <a href="#0" style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      this.nextstep(e, 22)
                                    }} className="text-muted mt-3">
                                    ยกเลิก
                                  </a>
                                  <button
                                    onClick={(e) => {
                                      this.validatergisterForm(e)
                                    }}
                                    type="submitx"
                                    className="btn btn-primary mt-lg-3 mt-0 -submit"
                                    disabled=""
                                  >
                                    ยืนยัน
                                  </button>
                                </div>
                                <input
                                  type="hidden"
                                  id="request_otp__token"
                                  name="request_otp[_token]"
                                  defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="-bottom ">
                            <div className="x-admin-contact text-center ">
                              <span className="x-text-with-link-component">
                                <label className="-text-message ">พบปัญหา</label>
                                <a
                                  href={this.state.linelink}
                                  className="-link-message "
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal "
            id="resetPasswordModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            data-container="#resetPasswordModal"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable "
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal m-auto">ลืมรหัสผ่าน</h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-modal-separator-container ">
                    <div className="-top " style={{ display: this.state.stateforget === 1 ? "" : "none" }}>
                      <div className="text-center d-flex flex-column">
                        <div className="text-center -img-container">
                          <img
                            src="/build/web/igame-index-lobby-wm/img/ic-reset-password.png"
                            alt="ลืมรหัสผ่าน"
                            className="img-fluid -ic-register"
                            width={160}
                            height={101}
                          />
                        </div>
                        <div className="-x-input-icon mb-3 flex-column text-center">
                          <img
                            src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                            className="-icon img-fluid"
                            alt="SA Casino phone icon"
                            width={10}
                            height={14}
                          />
                          <input
                            type="text"
                            onChange={this.onChangex}
                            id="phoneNumberforget"
                            name="phoneNumber"
                            required="required"
                            pattern="[0-9]*"
                            className="x-form-control form-control"
                            placeholder="เบอร์โทรศัพท์ที่เคยลงทะเบียน"
                            autoFocus="autoFocus"
                            autoComplete="on"
                            inputMode="text"
                          />
                          <div id="checkotpforgetphone" className="invalid-feedback ">
                            <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submitx"
                            onClick={(e) => {
                              this.nextstepforget(e, 1)
                            }}
                            className="btn  btn-primary my-lg-3 mt-0"
                          >
                            ยืนยัน
                          </button>
                        </div>
                      </div>
                      <input

                        type="hidden"
                        id="forgetpassword"
                        name="forgetpassword"
                        defaultValue="HqmR014dfy9ntoczFlsBdXJxpTTrQApRUP7nq2Q8zsc"
                      />
                    </div>
                    <div className="-top " style={{ display: this.state.stateforget === 2 ? "" : "none" }}>
                      <h3 className="x-title-modal mx-auto text-center ">
                        ยืนยัน OTP
                      </h3>
                      <div className="-img-container text-center">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet="https://cdn.javisx.com/trixie/build/web/register-icon-otp-v2.webp"
                          />
                          <source
                            type="image/png?v=5"
                            srcSet="https://cdn.javisx.com/trixie/build/web/register-icon-otp-v2.webp"
                          />
                          <img
                            alt="กรอกรหัส OTP เพื่อยืนยันตัวตน"
                            className="img-fluid -ic-otp"
                            width={150}
                            height={150}
                            src="https://cdn.javisx.com/trixie/build/web/register-icon-otp-v2.webp"
                          />
                        </picture>
                      </div>

                      <div className="-fake-inner-body">
                        <div >
                          <div className="-x-input-icon mb-3 text-center">
                            <img
                              src="/build/web/igame-index-lobby-wm/img/ic-input-phone.png"
                              className="-icon"
                              alt="SA Casino phone icon"
                              width={10}
                              height={14}
                            />
                            <input
                              onChange={this.onChangex}
                              type="text"
                              id="otpphoneforget"
                              name="otpphoneforget"
                              required="required"
                              pattern="[0-9]*"
                              maxLength="4"
                              className="x-form-control form-control"
                              placeholder="กรุณากรอก OTP 4 หลัก"
                              autoFocus="autoFocus"
                              autoComplete="on"
                              inputMode="text"
                            />
                            <div id="checkotpforget" className="invalid-feedback ">
                              <ul className="list-unstyled mb-0"><li>{stateopterror}</li></ul>
                            </div>
                          </div>
                          <div className="text-center">
                            <a href="#0" style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.nextstepforget(e, 11)
                              }} className="text-muted mt-3">
                              ยกเลิก
                            </a>
                          </div>
                          <div className="text-center">

                            <button
                              onClick={(e) => {
                                this.nextstepforget(e, 2)
                              }}
                              type="submitx"
                              className="btn   btn-primary mt-lg-3 mt-0"
                              disabled=""
                            >
                              ต่อไป
                            </button>
                          </div>
                          <input
                            type="hidden"
                            id="request_otp__token"
                            name="request_otp[_token]"
                            defaultValue="ds26nEjb1GacmW8racC7ScvGiCgTEnPl5xKWBzZyMWc"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="-top " style={{ display: this.state.stateforget === 3 ? "" : "none" }}>
                      <h3 className="x-title-modal mx-auto text-center ">
                        กรุณา Confirm Password
                      </h3>
                      <div className="-fake-inner-body">
                        <div >
                          <div className="text-center">
                            <div className="-x-input-icon mb-3 text-center">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                className="-icon"
                                alt="SA Casino phone icon"
                                width={10}
                                height={14}
                              />
                              <input
                                onChange={this.onChangex}
                                type="password"
                                id="password"
                                name="password"
                                required="required"
                                className="x-form-control form-control"
                                placeholder="กรุณารหัสผ่าน"
                                autoFocus="autoFocus"
                                autoComplete="on"
                                inputMode="text"
                              />
                            </div>
                            <div className="-x-input-icon mb-3 text-center">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/ic-input-lock.png"
                                className="-icon"
                                alt="SA Casino phone icon"
                                width={10}
                                height={14}
                              />
                              <input
                                onChange={this.onChangex}
                                type="password"
                                id="passwordcf"
                                name="passwordcf"
                                required="required"
                                className="x-form-control form-control"
                                placeholder="กรุณารหัสผ่าน"
                                autoFocus="autoFocus"
                                autoComplete="on"
                                inputMode="text"
                              />
                              <div className="mt-3 -spacer  text-center" style={{ display: (this.state.errorstatus ? "" : "none") }}>
                                <div className="x-danger-zone -v2  text-center">
                                  <span>{this.state.errortext}</span>
                                </div>
                              </div>
                            </div>
                            <a
                              href="#0" style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.nextstepforget(e, 11)
                              }} className="text-muted mt-3">
                              ยกเลิก
                            </a>
                            <button
                              onClick={(e) => {
                                this.resetpassword(e, 2)
                              }}
                              type="submitx"
                              className="btn btn-primary mt-lg-3 mt-0 -submit"
                              disabled=""
                            >
                              ยืนยัน
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="-bottom ">
                      <div className="x-admin-contact ">
                        <span className="x-text-with-link-component">
                          <label className="-text-message ">พบปัญหา</label>
                          <a
                            href={this.state.linelink}
                            className="-link-message "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal "
            id="alertModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            data-loading-container=".js-modal-content"
            data-ajax-modal-always-reload="true"
          >
            <div className="modal-dialog -modal-size  " role="document">
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal d-inline-block m-auto">
                    <span>แจ้งเตือน</span>
                  </h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="text-center my-3">
                    <img
                      src="/build/web/igame-index-lobby-wm/img/ic-alert-success.png"
                      alt="SUCCESS"
                      className="js-ic-success -img img-fluid lazyload"
                      width={100}
                      height={100}
                    />
                    <img
                      src="/build/web/igame-index-lobby-wm/img/ic-alert-failed.png"
                      alt="FAIL"
                      className="js-ic-fail -img img-fluid lazyload"
                      width={100}
                      height={100}
                    />
                  </div>
                  <div className="js-modal-content text-center f-4" />
                </div>
              </div>
            </div>
          </div>
          <div className="x-right-sidebar-container"></div>
          <div
            className="x-modal modal "
            id="themeSwitcherModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            data-loading-container=".js-modal-content"
            data-ajax-modal-always-reload="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable modal-dialog-centered modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content -modal-content">
                <div className="modal-body -modal-body">
                  <div className="x-theme-switcher">
                    <ul className="nav">
                      <li
                        className="nav-item -sa "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-sa-1.png"
                            alt="SA Casino คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">SA Casino</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -Secret789-VIP -active"
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-wm-1.png"
                            alt="Secret789-VIP Casino คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Secret789-VIP Casino</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -sexy "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-sexy-1.png"
                            alt="Sexy Gaming คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Sexy Gaming</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -dream "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-dream-1.png"
                            alt="Dream Gaming คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Dream Gaming</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -pretty "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-pretty-1.png"
                            alt="Pretty Gaming คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Pretty Gaming</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -ae "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-ae-1.png"
                            alt="Secret789-VIP คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Secret789-VIP</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -allbet "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-allbet-1.png"
                            alt="Allbet คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Allbet</div>
                        </a>
                      </li>
                      <li
                        className="nav-item -eg "
                        data-animatable="fadeInUp"
                        data-delay={100}
                      >
                        <a href="/" className="nav-link" rel="ugc,nofollow">
                          <img
                            src="/build/web/shared/img/logo-eg.png"
                            alt="Evo Gaming คาสิโนออนไลน์ สล็อตออนไลน์ ดีที่สุดในประเทศไทย logo png"
                            className="-logo"
                            width={50}
                            height={50}
                          />
                          <div className="-text">Evo Gaming</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* หน้าเลือกโปรก่อนเติม */}
          <div
            className="x-modal modal chooseprodps show"
            id="depositChoosePromotionModal"
            tabIndex={-1}
            role="dialog"
            data-container="#depositChoosePromotionModal"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable         -modal-bigger -modal-deposit-promotion -no-fixed-button
               "
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal m-auto">ฝากเงิน</h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-deposit-promotion d-flex flex-column">
                    <div className="-promotion-container row">

                      <a
                        href="#deposit"
                        className="col-md-4 col-sm-6 col-12 js-account-approve-aware js-cancel-promotion -promotion-card-link cancel-probtn"
                        data-toggle="modal"
                        data-dismiss="modal"
                        data-target="#depositModal"
                      >
                        <div className="x-card card -pure-deposit">
                          <div className="-img-container">
                            <img
                              src="/build/web/igame-index-lobby-wm/img/ic-promotion-not-received.png"
                              alt="บาคาร่า คาสิโน สล็อตออนไลน์ promotion default"
                              className="-img-no-accept-promotion -img img-fluid"
                            />
                          </div>
                          <div className="card-footer">

                            <button
                              onClick={(e) => this.confirmdeposit(e, 4)}
                              className="btn -btn -cancel-promotion-btn">
                              <span>เติมเงิน</span>
                            </button>
                          </div>
                        </div>
                      </a>
                      <div className="col-md-4 col-sm-6 col-12 -promotion-card-link -real-content" style={{ display: (this.state.tblPopupMenu === null) ? "none" : (this.state.tblPopupMenu[1].status !== 0) ? "" : "none" }}>
                        <a
                          className="d-block h-100 confirm-probtn"
                        >
                          <div className="x-card card -promotion">
                            <div className="-img-container ">
                              <img
                                src="https://play-lh.googleusercontent.com/6I2IYbIg4rhGUgs0UxP_5q6wmJmlBjBrlQ9f0_FAN94yOzwmrtEteifCdPPd1-chY_NX"
                                className="-img-promotion -img img-fluid ls-is-cached lazyloaded"
                                alt="ฝาก 1000 ฟรี 100 ไม่ติดเงื่อนไข"
                                width={500}
                                height={500}
                              />
                            </div>
                            <div className="card-footer">
                              <button className="btn -btn -cancel-promotion-btn" onClick={(e) => this.confirmdeposit(e, 5)}>
                                <span>เติมทรูมันนี่</span>
                              </button>
                              <button id="truemonney" style={{ display: "none" }} data-toggle="modal" data-target="#deposittruemoneyModal" data-dismiss="modal"  >
                              </button>
                            </div>
                          </div>
                        </a>
                      </div>
                      {this.state.tblPopupMenu2.map((listitem, i) => (
                        <div className="col-md-4 col-sm-6 col-12 -promotion-card-link -real-content">
                          <a
                            className="d-block h-100 confirm-probtn"
                          >
                            <div className="x-card card -promotion">
                              <div className="-img-container ">
                                <img
                                  src="https://play-lh.googleusercontent.com/6I2IYbIg4rhGUgs0UxP_5q6wmJmlBjBrlQ9f0_FAN94yOzwmrtEteifCdPPd1-chY_NX"
                                  className="-img-promotion -img img-fluid ls-is-cached lazyloaded"
                                  alt="ฝาก 1000 ฟรี 100 ไม่ติดเงื่อนไข"
                                  width={500}
                                  height={500}
                                />
                              </div>
                              <div className="card-footer">
                                <button className="btn -btn -cancel-promotion-btn" onClick={(e) => this.confirmdeposit(e, 6)}>
                                  <span>เติมทรูมันนี่</span>
                                </button>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className="my-3 mt-auto">
                      <div className="x-admin-contact -bg -no-fixed">
                        <span className="x-text-with-link-component">
                          <label className="-text-message ">ติดปัญหา</label>
                          <a
                            href={this.state.linelink}
                            className="-link-message "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* หน้าเลือกโปรก่อนเติม */}
          {/* เติมเงิน */}
          <div
            className="x-modal modal show"
            id="depositModal"
            tabIndex={-1}
            role="dialog"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-deposit"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal m-auto">ฝากเงิน</h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-deposit-form">
                    <div className="row -deposit-container" style={{ display: (this.state.depositstate === 0 ? " none" : "") }} >
                      <div
                        data-animatable="fadeInModal"
                        className="col-lg order-lg-2 -form order-0  fadeInModal"
                      >
                        <div className="-deposit-notice-inner-wrapper">
                          <div className="x-deposit-notice  ">
                            <div className="my-3 -spacer" style={{ textAlign: "center" }}>
                              {this.state.qrcode !== null ?
                                <img
                                  src={this.state.qrcode}
                                  className="-img-text-promotion img-fluid ls-is-cached lazyloaded"
                                  style={{ backgroundColor: "black" }}
                                  alt=""
                                  width={150}
                                  height={50}
                                /> : ""
                              }
                            </div>
                            <div className="my-3 -spacer">
                              <div className="-fake-input py-3 m-auto h3 -value-deposit x-input-operator  -text-big ">
                                <input
                                  type="text"
                                  id="depoamount"
                                  style={{ color: "black", font: "large" }}
                                  value={this.state.depositamount}
                                  disabled
                                  name="deposit1"
                                  required="required"
                                  pattern="[0-9]*"
                                  className="x-form-control -text-big text-center js-deposit-input-amount form-control"
                                  placeholder="เงินฝากขั้นต่ำ 100"
                                  inputMode="text"
                                />

                                <span className=""></span>
                              </div>
                            </div>
                            {mainacc !== null ? <div className="mt-4 -spacer">
                              <div className="x-deposit-bank-info-container -v2">
                                <div className="media m-auto">
                                  <img src={mainacc.img_path} className="-img rounded-circle" width="50" height="50" alt="bank-bay" />
                                  <div className="-content-wrapper">
                                    <span className="-name">ธนาคาร {mainacc.fullname_th}</span>
                                    <span className="-own-name">{mainacc.first_name_en + " " + mainacc.last_name_en} </span>
                                    <span id="depositBankNumber" className="-number">{
                                      mainacc.accnumber.substring(0, 3) + "-" + mainacc.accnumber.substring(3, 9) + "-" + mainacc.accnumber.substring(9, 12)
                                    }</span>
                                  </div>
                                  <div className="-copy-wrapper">
                                    <a href={() => false} style={{ cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(mainacc.accnumber)} className="btn -btn js-copy-to-clipboard" data-container="depositModal" data-message="คัดลอกแล้ว" data-copy-me={mainacc.accnumber}>คัดลอก</a>
                                  </div>
                                </div>
                              </div>

                            </div> : ""}
                            <div className="mt-3 -spacer">
                              <div className="x-danger-zone -v2 ">
                                <span>กรุณาใช้เลขบัญชีที่สมัครโอนเข้ามาเท่านั้น</span>
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={(e) => {
                                this.confirmdeposit(e, 1)
                              }}
                              className="btn btn-block btn-primary  mt-4 "
                              data-dismiss="modal"
                            >
                              รับทราบ
                            </button>
                            <div className="mt-4">
                              <div className="x-admin-contact -no-fixed">
                                <span className="x-text-with-link-component">
                                  <label className="-text-message ">หากพบปัญหา</label>
                                  <a
                                    href={this.state.linelink}
                                    className="-link-message "
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="row -deposit-container" style={{ display: (this.state.depositstate === 1 ? " none" : "") }}>
                      <div
                        data-animatable="fadeInModal"
                        className="col-lg order-lg-2 -form order-0  fadeInModal"
                      >
                        <div className="-deposit-form-inner-wrapper">
                          <div
                            noValidate=""
                            name="deposit"
                            method="post"
                          >
                            <div className="-modal-body-top">
                              <div className="text-center d-flex flex-column">

                                {proderedeem.length === 0 ?
                                  <div className="m-auto">
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/ic-deposit-heading.png"
                                      className="img-fluid -ic-deposit"
                                      alt="ฝากเงินออโต้ 24 ชั่วโมง คาสืโนออนไลน์"
                                      width={140}
                                      height={140}
                                    />
                                  </div>
                                  : <div className="x-deposit-promotion-outer-container js-scroll-ltr -fade -on-left -on-right">
                                    <div className="x-deposit-promotion -v2 -slide pt-0" data-scroll-booster-container=".x-deposit-promotion-outer-container" data-scroll-booster-content=".x-deposit-promotion" style={{ transform: 'translate(0px, 0px)' }}>
                                      {proderedeem.map((listitem, i) => (
                                        <div key={i} className="-promotion-box-wrapper">
                                          <button type="button" className="btn -promotion-box-apply-btn js-promotion-apply" data-type="deposit" data-display-slide-mode="true">
                                            <img src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/ic-gift.png" className="-img" alt={listitem.proname} width={26} height={26} />
                                            <span className="-title">{listitem.proname}</span>
                                          </button>
                                          <a href={() => false} style={{ cursor: "pointer" }} className="-promotion-box-cancel-btn js-cancel-promotion d-none" data-display-slide-mode="true">
                                            <i className="fas fa-times" />
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>}
                              </div>
                              <div className="-promotion-intro-deposit">
                                <a
                                  href="#deposit-choose-promotion"
                                  className="-deposit-back-btn js-account-approve-aware btn -back-btn"
                                  data-toggle="modal"
                                  data-target="#depositChoosePromotionModal"
                                  data-dismiss="modal"
                                >
                                  <div className="-text">ต้องการรับโปรโมชั่น</div>
                                </a>
                                <div className="js-promotion-active-html"></div>
                              </div>
                              <div className="-x-input-icon x-input-operator mb-4 flex-column">

                                <input
                                  type="text"
                                  id="depositamount"
                                  onChange={this.onChangexdep}
                                  value={this.state.depositamount}
                                  name="depositamount"
                                  required="required"
                                  pattern="[0-9]*"
                                  className="x-form-control -text-big text-center js-deposit-input-amount form-control"
                                  placeholder={(limits === null) ? "" : "เงินฝากขั้นต่ำ " + limits.min + " สูงสุด " + limits.max + " บาท"}
                                  inputMode="text"
                                />
                              </div>
                              <div
                                className="x-select-amount js-quick-amount"
                                data-target-input="#deposit_amount"
                              >
                                <div className="-amount-container">
                                  <button
                                    onClick={(e) => this.handleClickdeposit(e, 300)}
                                    type="button"
                                    className="btn btn-block -btn-select-amount"
                                    data-amount={300}
                                  >
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                      className="-deposit-coin"
                                      alt=""
                                    />
                                    <span className="-no">300</span>
                                  </button>
                                </div>
                                <div className="-amount-container">
                                  <button
                                    type="button"
                                    onClick={(e) => this.handleClickdeposit(e, 500)}
                                    className="btn btn-block -btn-select-amount"
                                    data-amount={500}
                                  >
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                      className="-deposit-coin"
                                      alt=""
                                    />
                                    <span className="-no">500</span>
                                  </button>
                                </div>
                                <div className="-amount-container">
                                  <button
                                    type="button"
                                    className="btn btn-block -btn-select-amount"
                                    onClick={(e) => this.handleClickdeposit(e, 1000)}
                                    data-amount={1000}
                                  >
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                      className="-deposit-coin"
                                      alt=""
                                    />
                                    <span className="-no">1000</span>
                                  </button>
                                </div>
                                <div className="-amount-container">
                                  <button
                                    type="button"
                                    className="btn btn-block -btn-select-amount"
                                    onClick={(e) => this.handleClickdeposit(e, 2000)}
                                    data-amount={2000}
                                  >
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                      className="-deposit-coin"
                                      alt=""
                                    />
                                    <span className="-no">2000</span>
                                  </button>
                                </div>
                                <div className="-amount-container">
                                  <button
                                    type="button"
                                    className="btn btn-block -btn-select-amount"
                                    onClick={(e) => this.handleClickdeposit(e, 5000)}
                                    data-amount={5000}
                                  >
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                      className="-deposit-coin"
                                      alt=""
                                    />
                                    <span className="-no">5000</span>
                                  </button>
                                </div>
                                <div className="-amount-container">
                                  <button
                                    type="button"
                                    className="btn btn-block -btn-select-amount"
                                    onClick={(e) => this.handleClickdeposit(e, 10000)}
                                    data-amount={10000}
                                  >
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                      className="-deposit-coin"
                                      alt=""
                                    />
                                    <span className="-no">10000</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="submitx"
                                onClick={(e) => this.confirmdeposit(e, 0)}
                                className="btn  btn-primary my-0 my-lg-3"
                              >
                                ยืนยัน
                              </button>
                            </div>
                            <div className="x-admin-contact ">
                              <span className="x-text-with-link-component">
                                <label className="-text-message ">พบปัญหา</label>
                                <a
                                  href={this.state.linelink}
                                  className="-link-message "
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                </a>
                              </span>
                            </div>
                            <input
                              type="hidden"
                              id="deposit__token"
                              name="deposit[_token]"
                              defaultValue="d8iYDTklPETlCIEBA5CM4b1J9xZS1ZFfMKUoJ-c21fE"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal show"
            id="deposittruemoneyModal"
            tabIndex={-1}
            role="dialog"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-deposit"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal m-auto">ฝากเงินทรูมันนี่</h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-deposit-form">
                    <div className="row -deposit-container" >
                      <div
                        data-animatable="fadeInModal"
                        className="col-lg order-lg-2 -form order-0  fadeInModal"
                      >
                        <div className="-deposit-form-inner-wrapper">
                          <div
                            noValidate=""
                            name="deposit"
                            method="post"
                          >
                            <div className="-modal-body-top">
                              <div className="text-center d-flex flex-column">

                                {proderedeem.length === 0 ?
                                  <div className="m-auto">
                                    <img
                                      src="/build/web/igame-index-lobby-wm/img/ic-deposit-heading.png"
                                      className="img-fluid -ic-deposit"
                                      alt="ฝากเงินออโต้ 24 ชั่วโมง คาสืโนออนไลน์"
                                      width={140}
                                      height={140}
                                    />
                                  </div>
                                  : <div className="x-deposit-promotion-outer-container js-scroll-ltr -fade -on-left -on-right">
                                    <div className="x-deposit-promotion -v2 -slide pt-0" data-scroll-booster-container=".x-deposit-promotion-outer-container" data-scroll-booster-content=".x-deposit-promotion" style={{ transform: 'translate(0px, 0px)' }}>
                                      {proderedeem.map((listitem, i) => (
                                        <div key={i} className="-promotion-box-wrapper">
                                          <button type="button" className="btn -promotion-box-apply-btn js-promotion-apply" data-type="deposit" data-display-slide-mode="true">
                                            <img src="https://asset.cloudigame.co/build/admin/img/wt_theme/ezc/ic-gift.png" className="-img" alt={listitem.proname} width={26} height={26} />
                                            <span className="-title">{listitem.proname}</span>
                                          </button>
                                          <a href={() => false} style={{ cursor: "pointer" }} className="-promotion-box-cancel-btn js-cancel-promotion d-none" data-display-slide-mode="true">
                                            <i className="fas fa-times" />
                                          </a>
                                        </div>
                                      ))}

                                    </div>
                                  </div>}
                              </div>
                              <div className="-promotion-intro-deposit">
                                <a
                                  href="#"
                                  className="-deposit-back-btn js-account-approve-aware btn -back-btn"
                                >
                                  <div className="-text">

                                    <img
                                      style={{ display: (this.state.btnisActive) ? "none" : "" }}
                                      src="/build/web/igame-index-lobby-wm/img/please-wait-animation.gif"
                                      className="img-fluid -ic-deposit"
                                      alt="ฝากเงินออโต้ 24 ชั่วโมง คาสืโนออนไลน์"
                                      width={140}
                                      height={140}
                                    />
                                    <br></br>
                                    {this.state.statetrue}

                                  </div>
                                </a>
                                <div className="js-promotion-active-html"></div>
                              </div>
                              <div className="-x-input-icon x-input-operator mb-4 flex-column">

                                <input
                                  type="text"
                                  id="depositamount"
                                  onChange={this.onChangexgiftlink}
                                  value={this.state.truegiftlink}
                                  name="depositamount"
                                  placeholder='ใส่ลิ้งของขวัญ'
                                  required="required"
                                  className="x-form-control -text-big text-center js-deposit-input-amount form-control"
                                  inputMode="text"
                                />
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="submitx"
                                onClick={(e) => this.confirmdeposittrue(e, 0)}
                                className="btn  btn-primary my-0 my-lg-3"
                              >
                                ยืนยัน
                              </button>
                            </div>
                            <div className="x-admin-contact ">
                              <span className="x-text-with-link-component">
                                <label className="-text-message ">พบปัญหา</label>
                                <a
                                  href={this.state.linelink}
                                  className="-link-message "
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                </a>
                              </span>
                            </div>
                            <input
                              type="hidden"
                              id="deposit__token"
                              name="deposit[_token]"
                              defaultValue="d8iYDTklPETlCIEBA5CM4b1J9xZS1ZFfMKUoJ-c21fE"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* เติมเงิน */}
          {/* หน้าถอนเงิน */}
          <div
            className="x-modal modal show"
            id="withdrawModal"
            tabIndex={-1}
            role="dialog"
            data-container="#withdrawModal"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-mobile"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal m-auto">ถอนเงิน</h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-withdraw-form">
                    <div
                      noValidate=""
                      name="withdraw"
                      method="post"
                      data-ajax-form="/account/_ajax_/withdraw"
                      data-container="#withdrawModal"
                    >
                      <div
                        data-animatable="fadeInModal"
                        className="-animatable-container  fadeInModal"
                      >
                        <div className="text-center d-flex flex-column">
                          <div className="m-auto">
                            <img
                              src="/build/web/igame-index-lobby-wm/img/ic-withdraw-heading.png"
                              className="img-fluid -ic-withdraw"
                              alt="ถอนเงินออโต้ 24 ชั่วโมง คาสืโนออนไลน์"
                              width={140}
                              height={140}
                            />
                          </div>
                          <div className="-x-input-icon x-input-operator mb-3 flex-column">
                            {/* <button
                            type="button"
                            className="-icon-left -btn-icon js-adjust-amount-by-operator"
                            data-operator="-"
                            data-value={10}
                          > 
                            <i className="fas fa-minus-circle" />
                          </button>*/}
                            <input
                              type="text"
                              id="withdraw_amount"
                              onChange={this.onChange}
                              value={this.state.withdrawamount}
                              name="withdrawamount"
                              required="required"
                              pattern="[0-9]*"
                              className="x-form-control -text-big text-center js-withdraw-input-amount form-control"
                              placeholder="เงินถอนขั้นต่ำ 300"
                              inputMode="text"
                            />
                            {/* <button
                            type="button"
                            className="-icon-right -btn-icon js-adjust-amount-by-operator"
                            data-operator="+"
                            data-value={10}
                          >
                            <i className="fas fa-plus-circle" />
                          </button> */}
                          </div>
                          <div
                            className="x-select-amount js-quick-amount"
                            data-target-input="#withdraw_amount"
                          >
                            <div className="-amount-container">
                              <button
                                onClick={(e) => this.handleClick(e, 300)}
                                type="button"
                                className="btn btn-block -btn-select-amount"
                                data-amount={300}
                              >
                                <img
                                  src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                  className="-deposit-coin"
                                  alt=""
                                />
                                <span className="-no">300</span>
                              </button>
                            </div>
                            <div className="-amount-container">
                              <button
                                type="button"
                                onClick={(e) => this.handleClick(e, 500)}
                                className="btn btn-block -btn-select-amount"
                                data-amount={500}
                              >
                                <img
                                  src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                  className="-deposit-coin"
                                  alt=""
                                />
                                <span className="-no">500</span>
                              </button>
                            </div>
                            <div className="-amount-container">
                              <button
                                type="button"
                                className="btn btn-block -btn-select-amount"
                                onClick={(e) => this.handleClick(e, 1000)}
                                data-amount={1000}
                              >
                                <img
                                  src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                  className="-deposit-coin"
                                  alt=""
                                />
                                <span className="-no">1000</span>
                              </button>
                            </div>
                            <div className="-amount-container">
                              <button
                                type="button"
                                className="btn btn-block -btn-select-amount"
                                onClick={(e) => this.handleClick(e, 2000)}
                                data-amount={2000}
                              >
                                <img
                                  src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                  className="-deposit-coin"
                                  alt=""
                                />
                                <span className="-no">2000</span>
                              </button>
                            </div>
                            <div className="-amount-container">
                              <button
                                type="button"
                                className="btn btn-block -btn-select-amount"
                                onClick={(e) => this.handleClick(e, 5000)}
                                data-amount={5000}
                              >
                                <img
                                  src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                  className="-deposit-coin"
                                  alt=""
                                />
                                <span className="-no">5000</span>
                              </button>
                            </div>
                            <div className="-amount-container">
                              <button
                                type="button"
                                className="btn btn-block -btn-select-amount"
                                onClick={(e) => this.handleClick(e, 10000)}
                                data-amount={10000}
                              >
                                <img
                                  src="/build/web/igame-index-lobby-wm/img/deposit-coin.png"
                                  className="-deposit-coin"
                                  alt=""
                                />
                                <span className="-no">10000</span>
                              </button>
                            </div>
                          </div>

                        </div>
                        <div className="x-transaction-current-balance">
                          <span className="-label">เครดิตที่มี</span>
                          <div className="-balance-wrapper">
                            <span id="customer-transaction-balance" className="js-customer-balance">
                              <span className="-amount">{balance}</span>
                            </span>
                            <button type="button"
                              onClick={(e) => {
                                this.recheccredit(e)
                              }}
                              id="btn-transaction-balance-reload"
                              className="btn btn-link -reload-btn p-0" >
                              <i className="fas fa-sync-alt "></i>
                            </button>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            onClick={(e) => this.submitwithdraw(e)}
                            type="submitx"
                            className="btn  btn-primary my-0 my-lg-3"
                          >
                            ยืนยัน
                          </button>
                        </div>
                        <div className="x-admin-contact  ">
                          <span className="x-text-with-link-component">
                            <label className="-text-message ">พบปัญหา</label>
                            <a
                              href={this.state.linelink}
                              className="-link-message "
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <u>ติดต่อฝ่ายบริการลูกค้า</u>
                            </a>
                          </span>
                        </div>
                      </div>
                      <input
                        type="hidden"
                        id="withdraw__token"
                        name="withdraw[_token]"
                        defaultValue="c9r5ElsPc_p6xmEjh7dv3LZcDaRwsVryr1p19a0qEcY"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* หน้าถอนเงิน */}
          <div
            className="x-modal modal -v2 show"
            id="accountModalMobile"
            tabIndex={-1}
            role="dialog"
            data-container="#accountModalMobile"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <div className="x-modal-mobile-header">
                    <div className="-header-mobile-container">
                      <h3 className="x-title-modal mx-auto text-center d-inline-block">
                        ข้อมูลบัญชี
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-account-profile -v2">
                    <div
                      data-animatable="fadeInModal"
                      className="-profile-container  fadeInModal"
                    >
                      <div className="x-profile-group-trace-body" >
                        <div className="my-3 ">
                          <div className="-profile-info-wrapper">
                            <div className=" x-profile-info -group-trace-type" >
                              <div className="-profile-image-wrapper">
                                <div className="x-profile-info-image">
                                  <img
                                    style={{ marginTop: "30px" }}
                                    className="img-fluid -profile-image"
                                    src={"/build/rank/" + this.state.rankpic}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="-inner-wrapper">
                                <div className="-username-wrapper">
                                  <span className="-label">
                                    Rank : <span className="-label-highlight"> {this.state.rankname}</span>
                                  </span>
                                  <span className="-value">{username}</span>

                                </div>
                                <a href={() => false} style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    this.openchangepassword(e, 'bonusaccount')
                                  }}
                                  className="-link-change-password" ><u>เปลี่ยนรหัสผ่าน</u></a>
                                <div className="x-checkbox-primary">
                                  <div
                                    className={"-change-password-container js-change-password-collapse collapse" + (changepassword ? "d-none" : "")}
                                  >
                                    <div className="js-collapse-content">
                                      <div
                                        noValidate=""
                                        name="sylius_user_change_password"
                                        method="post"
                                      >
                                        <div className="form-group mt-3">
                                          <input
                                            onChange={this.onChangex}
                                            type="password"
                                            id="oldpassword"
                                            name="oldpassword"
                                            required="required"
                                            placeholder="รหัสผ่านปัจจุบัน"
                                            className="x-form-control form-control"
                                          />
                                        </div>
                                        <div className="form-group">
                                          <input
                                            onChange={this.onChangex}
                                            type="password"
                                            id="password"
                                            name="password"
                                            required="required"
                                            placeholder="รหัสผ่านใหม่"
                                            className="x-form-control form-control"
                                          />
                                        </div>
                                        <div className="form-group">
                                          <input
                                            onChange={this.onChangex}
                                            type="password"
                                            id="passwordcf"
                                            name="passwordcf"
                                            required="required"
                                            placeholder="การยืนยัน"
                                            className="x-form-control form-control"
                                          />
                                        </div>
                                        <button
                                          onClick={(e) => {
                                            this.resetpassword(e, 1)
                                          }}
                                          type="submit"
                                          style={{ background: "linear-gradient(180deg, #23e500, #1be305" }}
                                          className="text-white js-account-approve-aware btn -btn-deposit"
                                        >
                                          <span>ยืนยัน</span>
                                        </button>
                                        <input
                                          type="hidden"
                                          id="sylius_user_change_password__token"
                                          name="sylius_user_change_password[_token]"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="-group-trace-wrapper">
                            <div className="x-profile-group-trace-condition ">
                              <div className="-card-condition-wrapper">
                                <div className="-card-condition-heading">
                                  <span className="-title">
                                    เงื่อนไขขั้น {(tblnextrank === null) ? "" : tblnextrank.name}
                                    {(tblnextrank === null) ? "" : <img
                                      style={{ marginLeft: "10px" }}
                                      className="img-fluid -profile-image"
                                      src={"/build/rank/" + tblnextrank.image}
                                      alt=""
                                    />}
                                  </span>
                                </div>
                                <div className="-card-condition-description">
                                  ฝากอีก <span className="-highlight">{(rankob === null) ? "" : (rankob.highamount - rankpoint + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> บาท
                                </div>
                                <p className="-note">ขั้น Member จะได้รับสิทธิพิเศษและโปรโมชั่นที่ดียิ่งขึ้น</p>
                                <div className="containerx">
                                  <div className="skill php" style={{ width: (rankob === null) ? "" : ((rankpoint / rankob.highamount) * 100) + "%" }} >  {rankpoint.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                </div>
                                <a href={() => false} data-container="#accountModal" onClick={(e) => {
                                  this.openAccout(e, 'bonusrankaccount')
                                }} style={{ marginTop: "10px", cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit"><div className="f-7">เคลม</div></a>
                                <div style={{ float: "left" }} >{(rankob === null) ? "" : rankob.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                                <div style={{ float: "right" }} >{(rankob === null) ? "" : (rankob.highamount + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="-bank-info-wrapper">
                          <div className="x-profile-bank-info" id="shareAccountProfileBankInfo">
                            <span className="-heading-title">ข้อมูลบัญชี</span>
                            <div className="-bank-detail">
                              <img
                                src={accnumber !== null ? accnumberx.img_path : ""}
                                alt={fullname}
                                width="50px"
                                className="mr-3 rounded-circle"
                              />
                              <span className="-name">ธนาคารกสิกรไทย</span>
                            </div>
                            <div className="-bank-number">
                              <span className="-label">เลขบัญชี : </span>
                              <a href={() => false} style={{ cursor: "pointer" }} className="-copy-wrapper js-copy-to-clipboard" data-container="shareAccountProfileBankInfo" data-message="คัดลอกแล้ว!" data-copy-me={accnumberx}>
                                {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                              </a>
                            </div>

                            <div className="-bank-name">
                              <span className="-label">ชื่อบัญชี : </span>
                              <span className="-value">{fullname}</span>
                            </div>
                          </div>
                          <div className="x-admin-contact -no-fixed text-center text-lg-left">
                            <span className="x-text-with-link-component">
                              <label className="-text-message ">*ต้องการเปลี่ยนบัญชี กรุณา</label>
                              <a href="https://lin.ee/BkV2cy4" className="-link-message " target="_blank" rel="noopener noreferrer">
                                <u>ติดต่อฝ่ายบริการลูกค้า</u>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="js-has-info" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal -v2 show"
            id="providerUserModalMobile"
            tabIndex={-1}
            role="dialog"
            data-container="#providerUserModalMobile"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <div className="x-modal-mobile-header">
                    <div className="-header-mobile-container">
                      <h3 className="x-title-modal mx-auto text-center d-inline-block">
                        ชวนเพื่อน
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-body -modal-body">
                  <div id="accountProviderUser" className="x-account-provider ">
                    <div
                      data-animatable="fadeInModal"
                      className="-account-provider-container animated fadeInModal"
                    >
                      <div className="-group-trace-wrapper">
                        <div className="x-profile-group-trace-condition ">
                          <div className="-card-condition-wrapper">
                            <div className="-card-condition-heading">
                              <span className="-title">
                                รับลิงค์ชวนเพื่อน
                              </span>
                            </div>
                            <div className="-card-condition-description">
                              กดที่ลิงค์ <span className="-highlight">
                                <a href={() => false} style={{ cursor: "pointer" }} onClick={() => this.copyCode()} className="-copy-wrapper js-copy-to-clipboard" data-container="shareAccountProfileBankInfo" data-message="คัดลอกแล้ว!" >
                                  {linkaff}
                                </a>
                              </span>
                            </div>
                            <p className="-note">รายชื่อที่มี
                              <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                className="-ic"
                                style={{ marginLeft: "5px" }}
                                width={"20px"}
                                alt=""
                              />  ครบเงื่อนใขการเติมเงินขั่นต่ำ 100 บาทแล้ว </p>
                            <span className="-title">
                              คุณได้รับเครดิตชวนเพื่อน<span className="-title" style={{ color: "#d9ad70" }}  > {tblprofile.AffiliateCash}  </span> บาท (คำนวณทุกวันอาทิตย์ เวลา 00.00น.)
                            </span>
                            {/* <span className="-title">
                            คุณได้รับเครดิตยอดเสีย<span className="-title" style={{ color: "#d9ad70" }}  > {cashoutSet != null ? cashoutSet.percent : "0"}  </span> บาท (คำนวณทุกวันอาทิตย์ เวลา 00.00น.)
                          </span> */}

                          </div>
                          <span className="-title">
                            คุณได้เปอเซนต์  <span className="-title" style={{ color: "#d9ad70" }}  >{tblprofile.AffPercent}%</span> จากยอดเสีย
                          </span>
                          <div className="-outer-history-wrapper" style={{ marginTop: "10px" }} >
                            <div className="x-bill-history-container">
                              <div
                                className="wg-container wg-container__wg_bill_history wg--loaded"
                                data-widget-name="wg_bill_history"
                                data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                                data-widget-user-options='{"page":1}'
                              >
                                <div className="wg-content" style={{ display: (this.state.useradvice.length === 0) ? "" : "none" }}>
                                  <div className="-no-result-wrapper mt-3">
                                    <div className="-heading-title">
                                      <i className="fas fa-times-circle -icon" />
                                      <div className="-title">ไม่พบข้อมูล</div>
                                      <div className="-sub-title" style={{ color: "black" }}>คุณยังไม่มีข้อมูลการชวนเพื่อนค่ะ</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wg-content" style={{ display: (this.state.useradvice.length === 0) ? "none" : "" }}>
                                  <table className="table table-borderless table-striped">
                                    <tbody>
                                      {this.state.useradvice.map((listitem, i) => (
                                        <tr key={i} id={listitem.tid}>
                                          <td className="-description-body-wrapper">
                                            <div className="-title-wrapper">

                                              {i + 1 + "."} <span className="-title" style={{ marginLeft: "0px" }}> {listitem.playerid}

                                                {(listitem.checked !== 1) ? "" : <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                  className="-ic"
                                                  style={{ marginRight: "5px" }}
                                                  width={"20px"}
                                                  alt=""
                                                />}
                                              </span>
                                            </div>
                                          </td>
                                          <td className="-transaction-body-wrapper">
                                            <div className="-datetime">{moment(listitem.DateCreate).format('DD/MM/YY') + " - " + moment(listitem.DateCreate).format('HH:mm') + " น."} </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal -v2 show"
            id="couponModalMobile"
            tabIndex={-1}
            role="dialog"
            data-container="#couponModalMobile"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <div className="x-modal-mobile-header">
                    <div className="-header-mobile-container">
                      <h3 className="x-title-modal mx-auto text-center d-inline-block">
                        ใช้คูปอง
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-account-coupon">
                    <div
                      data-animatable="fadeInModal"
                      className="-coupon-container  fadeInModal"
                    >
                      <div className="-coupon-member-detail">
                        <div className="-coupon-box">
                          <img
                            src="/build/web/igame-index-lobby-wm/img/ic-coupon.png"
                            alt="คูปองเล่นคาสิโนออนไลน์ โบนัสสล็อตออนไลน์"
                            className="img-fluid -ic-coupon m-auto"
                            width={220}
                            height={94}
                          />
                        </div>
                      </div>
                      <div className="-form-coupon-container">
                        <form
                          name="coupon"
                          method="post"
                          action="/account/_ajax_/coupon-apply?isMobileView=1"
                          data-ajax-form="/account/_ajax_/coupon-apply?isMobileView=1"
                          data-callback="_onCouponApply_"
                          data-dismiss-modal="#couponModalMobile"
                          data-container="#couponModalMobile"
                        >
                          <div className="my-4 -x-input-icon">
                            <img
                              src="/build/web/igame-index-lobby-wm/img/ic-coupon-input.png"
                              className="-icon"
                              alt="icon-coupon"
                            />
                            <input
                              type="text"
                              onChange={this.onChangex}
                              id="couponkey"
                              name="couponkey"
                              required="required"
                              className="x-coupon-input form-control"
                              placeholder="รหัสคูปอง"
                            />
                          </div>
                          <div className="-btn-container">
                            <button type="submit"
                              onClick={(e) => {
                                this.couponredeem(e)
                              }} className="btn  btn-primary">

                              ยืนยัน
                            </button>
                          </div>
                          <input
                            type="hidden"
                            id="coupon__token"
                            name="coupon[_token]"
                            defaultValue="kg4A5PhTS8tsqjpMl7ef3x1ECaVkawFij6MAsvN55G0"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="x-modal modal -v2 show"
            id="promotionReturnByUserModalMobile"
            tabIndex={-1}
            role="dialog"
            data-container="#promotionReturnByUserModalMobile"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <div className="x-modal-mobile-header">
                    <div className="-header-mobile-container">
                      <h3 className="x-title-modal mx-auto text-center d-inline-block">
                        รับโบนัสยอดเสีย {cashoutSet.percent} %
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-promotion-return-by-user-container">
                    <div className="-group-round-container -no-data">
                      <div className="-date-range-container text-center">
                        ยอดโบนัสระหว่างวันที่  {moment(cashoutSet.LastStartDate).format('DD') + " " + this.showClockRealTime(cashoutSet.LastStartDate) + " - " + moment(cashoutSet.LastEndDate).format('DD') + " " + this.showClockRealTime(cashoutSet.LastStartDate) + "  "}
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary -promotion-return-btn"
                        onClick={(e) => {
                          this.opencliamcashout(e, 'bonusrankaccount')
                        }}
                      >
                        {tblprofile.Cashtotal > 0 ? <span className="-text-btn">รับยอดเสีย</span> : <span className="-text-btn">ไม่เข้าเงื่อนไข</span>}

                      </button>
                    </div>
                    <div className="-description-container">
                      {tblprofile.Cashtotal > 0 ? <div>คุณได้รับยอดเสีย {tblprofile.Cashtotal} บาท</div> : <div>คุณไม่เข้าเงื่อนไขการรับโบนัส</div>}
                      <div>
                        <span className="-text-description">
                          โปรดอ่านเงื่อนไขการเข้าร่วม
                        </span>
                        ด้านล่างค่ะ
                      </div>
                    </div>
                    <div className="-condition-container">
                      <div className="-condition-title">
                        <u>โปรดอ่านเงื่อนไข</u>
                      </div>
                      <div className="x-promotion-content">
                        <h2>
                          <strong>
                            เล่นได้ให้เพิ่ม เล่นเสียให้คืน {cashoutSet.percent} % ทุกสัปดาห์
                          </strong>
                        </h2>
                        ► คำนวณยอดใน 1 สัปดาห์ (ตั้งแต่ จันทร์ 00:01 น. ถึง วันอาทิตย์
                        23:59 น.)
                        <br />
                        ► ต้องมียอดเทิร์นโอเวอร์ 5 เท่าของเงินฝากภายในสัปดาห์
                        <br />
                        ► โบนัสจะได้รับทุกวันจันทร์ สามารถกดรับโบนัสได้ที่หน้าเว็บ
                        <br />
                        ► โบนัสที่ได้รับไม่ต้องทำเทิร์นโอเวอร์ ถอนได้ทันที!
                        <br />
                        ► มียอดได้-เสียมากกว่า 500 บาทต่อสัปดาห์ จึงจะได้รับโบนัส
                        5%
                        <br />
                        ► เมื่อรับโปรโมชั่น เครดิตจะมีอายุการใช้งาน 7 วัน
                        จากนั้นเครดิตคืนยอดเสียจะถูกปรับเป็น 0&nbsp;
                        <br />
                        &nbsp;
                        <a href="/term-and-condition">
                          เงื่อนไขและกติกาพื้นฐานจะถูกนำมาใช้กับโปรโมชั่นนี้
                        </a>

                      </div>
                    </div>
                    <div className="my-3">
                      <div className="x-admin-contact -no-fixed">
                        <span className="x-text-with-link-component">
                          <label className="-text-message ">ติดปัญหา</label>
                          <a
                            href={this.state.linelink}
                            className="-link-message "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="x-modal modal -v2 show"
            id="promotionReturnByUserModalMobileRedeem"
            tabIndex={-1}
            role="dialog"
            data-container="#promotionReturnByUserModalMobileRedeem"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <div className="x-modal-mobile-header">
                    <div className="-header-mobile-container">
                      <h3 className="x-title-modal mx-auto text-center d-inline-block">
                        ขอรับโบนัส Rank
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-promotion-return-by-user-container">
                    <div className="-description-container">
                      <div>
                        <span className="-text-description">
                          โปรดอ่านเงื่อนไขการเข้าร่วม
                        </span>
                        ด้านล่างค่ะ
                      </div>
                    </div>
                    <div className="-condition-container" style={{ height: "450px", width: "480px" }}>
                      <div className="x-promotion-content">
                        <table className="table table-borderless table-striped">
                          <tbody>
                            {this.state.tblrankall.map((listitem, i) => (
                              <tr key={i} id={listitem.no}>
                                <td className="-description-body-wrapper">
                                  <img
                                    width={"100px"}
                                    className="img-fluid -profile-image"
                                    src={"/build/rank/" + listitem.image}
                                    alt=""
                                  />
                                  <div className="-title-wrapper">
                                    <p><span className="-title">{listitem.name}</span>        </p>
                                  </div>
                                  <div className="-state-wrapper">
                                    <span className="-text-description">{"เติม " + listitem.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท {(listitem.no === 1) ? "" : "โบนัส " + listitem.claimamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " บาท ชวนเพื่อน " + listitem.advicepercent + "%"} </span>
                                  </div>
                                </td>
                                <td className="-transaction-body-wrapper">
                                  {
                                    (listitem.no === 1) ? "" :
                                      (listitem.lowamount <= rankpoint && !listitem.checked) ? <div> <img
                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                        className="-ic"
                                        width={"30px"}
                                        alt=""
                                      /><span className="-state-title" style={{ marginLeft: "10px" }}>สำเร็จ</span></div> :
                                        (!listitem.checked) ? "ยังไม่ผ่านเงื่อนไข" : <a href={() => false} style={{ cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit active" onClick={(e) => {
                                          this.redeemrank(e, listitem)
                                        }}><div className="f-7">Cliam</div></a>}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="my-3">
                      <div className="x-admin-contact -no-fixed">
                        <span className="x-text-with-link-component">
                          <label className="-text-message ">ติดปัญหา</label>
                          <a
                            href={this.state.linelink}
                            className="-link-message "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* หน้าข้อมูลบัญชี */}
          <div
            className="x-modal modal show"
            id="accountModal"
            tabIndex={-1}
            role="dialog"
            data-container="#accountModal"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-big -modal-main-account"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-body -modal-body">
                  <div className="x-modal-account-menu">
                    <ul className="navbar-nav">
                      <li
                        className="nav-item -account-profile tabaccount active"
                        onClick={(e) => {
                          this.openAccout(e, 'mainaccount')
                        }}

                      >
                        <button
                          type="button"
                          className="nav-link js-close-account-sidebar"
                          data-container="#accountModal"
                        >
                          <img
                            className="img-fluid -icon-image"
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-user.png"
                            alt="icon user"
                            width={34}
                            height={34}
                          />
                          <span className="-text-menu">ข้อมูลบัญชี</span>
                        </button>
                      </li>
                      <li
                        className="nav-item -account-provider tabaccount"
                        onClick={(e) => {
                          this.openAccout(e, 'appaccount')
                        }}
                      >
                        <button
                          type="button"
                          className="nav-link js-close-account-sidebar"
                          data-container="#accountModal"
                          onClick={(e) => {
                            this.checkuseradvice()
                          }}
                        >
                          <img
                            className="img-fluid -icon-image"
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-provider.png"
                            alt="icon phone"
                            width={34}
                            height={34}
                          />
                          <span className="-text-menu">ชวนเพื่อน</span>
                        </button>
                      </li>
                      <li className="nav-item  -account-provider -account-bill-history tabaccount"
                        onClick={(e) => {
                          this.openAccout(e, 'apphistory')
                        }}>
                        <button
                          type="button"
                          className="nav-link js-close-account-sidebar"
                          data-container="#accountModal"
                        >
                          <img
                            alt="ประวัติ เว็บไซต์พนันออนไลน์ คาสิโนออนไลน์ สล็อตออนไลน์"
                            className="img-fluid -icon-image"
                            width={34}
                            height={34}
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-bill-history.png?v=5"
                          />
                          <span className="-text-menu">ประวัติ</span>
                        </button>
                      </li>

                      <li
                        className="nav-item -coupon tabaccount"
                        onClick={(e) => {
                          this.openAccout(e, 'couponaccount')
                        }}
                      >
                        <button
                          type="button"
                          className="nav-link js-close-account-sidebar js-account-approve-aware"
                          data-container="#accountModal"
                        >
                          <img
                            className="img-fluid -icon-image"
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-coupon.png"
                            alt="icon coupon"
                            width={34}
                            height={34}
                          />
                          <span className="-text-menu">ใช้คูปอง</span>
                        </button>
                      </li>
                      <li
                        className="nav-item -join-promotion tabaccount"
                        onClick={(e) => {
                          this.openAccout(e, 'proaccount')
                        }}
                      >
                        <button
                          type="button"
                          className="nav-link js-close-account-sidebar"
                          data-container="#accountModal"
                          onClick={(e) => {
                            this.checkpro(e, 999)
                          }}
                        >
                          <img
                            className="img-fluid -icon-image"
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-promotion.png"
                            alt="icon promotion"
                            width={34}
                            height={34}
                          />
                          <span className="-text-menu">โปรโมชั่นที่เข้าร่วม</span>
                        </button>
                      </li>
                      <li
                        className="nav-item -promotion-return-by-user tabaccount"
                        onClick={(e) => {
                          this.openAccout(e, 'bonusaccount')
                        }}
                      >
                        <button
                          type="button"
                          className="nav-link js-close-account-sidebar js-account-approve-aware"
                          data-container="#accountModal"
                        >
                          <img
                            className="img-fluid -icon-image"
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-bonus.png"
                            alt="รับคืนยอดเสีย"
                            width={34}
                            height={34}
                          />
                          <span className="-text-menu">รับคืนยอดเสีย</span>
                        </button>
                      </li>
                      <li className="nav-item js-close-account-sidebar -logout">
                        <a
                          href="/logout"
                          className="nav-link js-require-confirm"
                          data-title="ต้องการออกจากระบบ ?"
                        >
                          <img
                            className="img-fluid -icon-image"
                            src="/build/web/igame-index-lobby-wm/img/ic-menu-account-logout.png"
                            alt="icon logout"
                            width={34}
                            height={34}
                          />
                          <span className="-text-menu">ออกจากระบบ</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="js-profile-account-modal -layout-account"
                    id="mainaccount"
                    style={{ display: "block" }}
                  >
                    <div className="x-account-profile">
                      <div
                        data-animatable="fadeInModal"
                        className="-profile-container  fadeInModal"
                      >
                        <h3 className="x-title-modal mx-auto text-center ">
                          ข้อมูลบัญชี
                        </h3>
                        <div className="x-profile-group-trace-body" >
                          <div className="my-3 ">
                            <div className="-profile-info-wrapper">
                              <div className=" x-profile-info -group-trace-type" >
                                <div className="-profile-image-wrapper">
                                  <div className="x-profile-info-image">
                                    <img
                                      style={{ marginTop: "30px" }}
                                      className="img-fluid -profile-image"
                                      src={"/build/rank/" + this.state.rankpic}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="-inner-wrapper">
                                  <div className="-username-wrapper">
                                    <span className="-label">
                                      Rank : <span className="-label-highlight"> {this.state.rankname}</span>
                                    </span>
                                    <span className="-value">{username}</span>

                                  </div>
                                  <a href={() => false} style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      this.openchangepassword(e, 'bonusaccount')
                                    }}
                                    className="-link-change-password" ><u>เปลี่ยนรหัสผ่าน</u></a>
                                  <div className="x-checkbox-primary">
                                    <div
                                      className={"-change-password-container js-change-password-collapse collapse" + (changepassword ? "d-none" : "")}
                                    >
                                      <div className="js-collapse-content">
                                        <div
                                          noValidate=""
                                          name="sylius_user_change_password"
                                          method="post"
                                        >
                                          <div className="form-group mt-3">
                                            <input
                                              onChange={this.onChangex}
                                              type="password"
                                              id="oldpassword"
                                              name="oldpassword"
                                              required="required"
                                              placeholder="รหัสผ่านปัจจุบัน"
                                              className="x-form-control form-control"
                                            />
                                          </div>
                                          <div className="form-group">
                                            <input
                                              onChange={this.onChangex}
                                              type="password"
                                              id="password"
                                              name="password"
                                              required="required"
                                              placeholder="รหัสผ่านใหม่"
                                              className="x-form-control form-control"
                                            />
                                          </div>
                                          <div className="form-group">
                                            <input
                                              onChange={this.onChangex}
                                              type="password"
                                              id="passwordcf"
                                              name="passwordcf"
                                              required="required"
                                              placeholder="การยืนยัน"
                                              className="x-form-control form-control"
                                            />
                                          </div>
                                          <button
                                            onClick={(e) => {
                                              this.resetpassword(e, 1)
                                            }}
                                            type="submit"
                                            style={{ background: "linear-gradient(180deg, #23e500, #1be305" }}
                                            className="text-white js-account-approve-aware btn -btn-deposit"
                                          >
                                            <span>ยืนยัน</span>
                                          </button>
                                          <input
                                            type="hidden"
                                            id="sylius_user_change_password__token"
                                            name="sylius_user_change_password[_token]"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="-group-trace-wrapper">
                              <div className="x-profile-group-trace-condition ">
                                <div className="-card-condition-wrapper">
                                  <div className="-card-condition-heading">
                                    <span className="-title">
                                      เงื่อนไขขั้น {(tblnextrank === null) ? "" : tblnextrank.name}
                                      {(tblnextrank === null) ? "" : <img
                                        style={{ marginLeft: "10px" }}
                                        className="img-fluid -profile-image"
                                        src={"/build/rank/" + tblnextrank.image}
                                        alt=""
                                      />}
                                    </span>
                                  </div>
                                  <div className="-card-condition-description">
                                    ฝากอีก <span className="-highlight">{(rankob === null) ? "" : (rankob.highamount - rankpoint + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span> บาท
                                  </div>
                                  <p className="-note">ขั้น Member จะได้รับสิทธิพิเศษและโปรโมชั่นที่ดียิ่งขึ้น</p>
                                  <div className="containerx">
                                    <div className="skill php" style={{ width: (rankob === null) ? "" : ((rankpoint / rankob.highamount) * 100) + "%" }} >  {rankpoint.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                                  </div>
                                  <a href={() => false} data-container="#accountModal" onClick={(e) => {
                                    this.openAccout(e, 'bonusrankaccount')
                                  }} style={{ marginTop: "10px", cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit"><div className="f-7">เคลม</div></a>
                                  <div style={{ float: "left" }} >{(rankob === null) ? "" : rankob.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                                  <div style={{ float: "right" }} >{(rankob === null) ? "" : (rankob.highamount + 1).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="-bank-info-wrapper">
                            <div className="x-profile-bank-info" id="shareAccountProfileBankInfo">
                              <span className="-heading-title">ข้อมูลบัญชี</span>
                              <div className="-bank-detail">
                                <img
                                  src={accnumber !== null ? accnumberx.img_path : ""}
                                  alt={fullname}
                                  width="50px"
                                  className="mr-3 rounded-circle"
                                />
                                <span className="-name">ธนาคารกสิกรไทย</span>
                              </div>
                              <div className="-bank-number">
                                <span className="-label">เลขบัญชี : </span>
                                <a href={() => false} style={{ cursor: "pointer" }} className="-copy-wrapper js-copy-to-clipboard" data-container="shareAccountProfileBankInfo" data-message="คัดลอกแล้ว!" data-copy-me={accnumberx}>
                                  {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                </a>
                              </div>

                              <div className="-bank-name">
                                <span className="-label">ชื่อบัญชี : </span>
                                <span className="-value">{fullname}</span>
                              </div>
                            </div>
                            <div className="x-admin-contact -no-fixed text-center text-lg-left">
                              <span className="x-text-with-link-component">
                                <label className="-text-message ">*ต้องการเปลี่ยนบัญชี กรุณา</label>
                                <a href="https://lin.ee/BkV2cy4" className="-link-message " target="_blank" rel="noopener noreferrer">
                                  <u>ติดต่อฝ่ายบริการลูกค้า</u>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* <div className="-bank-info-container">
                        <div className="media">
                          <img
                            src={accnumber !== null ? accnumberx.img_path : ""}
                            alt={fname}
                            width="50px"
                            className="mr-3 rounded-circle"
                          />
                          <div className="media-body text-left">
                            <div className="f-6">{accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}</div>
                            <b>{fname}</b>
                          </div>
                        </div>
                      </div> */}
                        <div className="js-has-info" />
                      </div>
                    </div>
                  </div>
                  <div className="js-profile-account-modal -layout-account" id="apphistory">
                    <h3 className="x-title-modal mt-0 mb-3 mx-auto text-center">
                      ประวัติการทำรายการ
                    </h3>
                    <div className="-outer-history-wrapper">
                      <div className="x-bill-history-container">
                        <div
                          className="wg-container wg-container__wg_bill_history wg--loaded"
                          data-widget-name="wg_bill_history"
                          data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                          data-widget-user-options='{"page":1}'
                        >
                          <div className="wg-content" style={{ display: (this.state.historylist.length === 0) ? "" : "none" }}>
                            <div className="-no-result-wrapper mt-3">
                              <div className="-heading-title">
                                <i className="fas fa-times-circle -icon" />
                                <div className="-title">ไม่พบข้อมูล</div>
                                <div className="-sub-title">คุณยังไม่มีข้อมูลการทำรายการค่ะ</div>
                              </div>
                            </div>
                          </div>
                          <div className="wg-content" style={{ display: (this.state.historylist.length === 0) ? "none" : "" }}>
                            <table className="table table-borderless table-striped">
                              <tbody>
                                {this.state.historylist.map((listitem, i) => (
                                  <tr key={i} id={listitem.tid}>
                                    <td className="-description-body-wrapper">
                                      <div className="-title-wrapper">
                                        <span className="-title">{(listitem.type === "BN") ? "*โบนัสพิเศษ" : (listitem.type === "DE") ? "ฝากเงิน" : "ถอนเงิน"}</span>
                                        <span style={{ display: (listitem.status === "0") ? "" : "none", margin: "5px" }}>
                                          <a href={() => false} style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              this.cancelwithdraw(e, listitem)
                                            }}
                                            className="-link-change-password" ><u>ยกเลิก</u></a>
                                        </span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "2") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">กำลังดำเนินการ</span>
                                        <span className="-state-title -short">กำลังดำเนินการ</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "0") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">รอดำเนินการ</span>
                                        <span className="-state-title -short">รอดำเนินการ  </span>

                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "4") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          alt=""
                                        />
                                        <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                        <span className="-state-title -short">ยกเลิก (โดยผู้เล่น)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "5") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          alt=""
                                        />
                                        <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                        <span className="-state-title -short">ไม่สำเร็จ (โดยระบบ)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === "1") ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                          className="-ic"
                                          alt=""
                                        />
                                        <span className="-state-title">สำเร็จ</span>
                                        <span className="-state-title -short">สำเร็จ</span>
                                      </div>
                                    </td>
                                    <td className="-transaction-body-wrapper">
                                      <div className={(listitem.type === "DE" || listitem.type === "BN") ? "-amount -deposit" : "-amount -withdraw"}>{listitem.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                                      <div className="-datetime">{moment(listitem.createdate).format('DD/MM/YY') + " - " + moment(listitem.createdate).format('HH:mm') + " น."} </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="js-profile-account-modal -layout-account"
                    id="appaccount"
                  >
                    <div id="accountProviderUser" className="x-account-provider ">
                      <div
                        data-animatable="fadeInModal"
                        className="-account-provider-container animated fadeInModal"
                      >
                        <h3 className="x-title-modal mx-auto text-center ">ชวนเพื่อน</h3>
                        <div className="-group-trace-wrapper">
                          <div className="x-profile-group-trace-condition ">
                            <div className="-card-condition-wrapper">
                              <div className="-card-condition-heading">
                                <span className="-title">
                                  รับลิงค์ชวนเพื่อน
                                </span>
                              </div>
                              <div className="-card-condition-description">
                                กดที่ลิงค์ <span className="-highlight">
                                  <a href={() => false} style={{ cursor: "pointer" }} onClick={() => this.copyCode()} className="-copy-wrapper js-copy-to-clipboard" data-container="shareAccountProfileBankInfo" data-message="คัดลอกแล้ว!" >
                                    {linkaff}
                                  </a>
                                </span>
                              </div>
                              <p className="-note">รายชื่อที่มี
                                <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                  className="-ic"
                                  style={{ marginLeft: "5px" }}
                                  width={"20px"}
                                  alt=""
                                />  ครบเงื่อนใขการเติมเงินขั่นต่ำ 100 บาทแล้ว </p>
                              <span className="-title">
                                คุณได้รับเครดิตชวนเพื่อน<span className="-title" style={{ color: "#d9ad70" }}  > {tblprofile.AffiliateCash}  </span> บาท (คำนวณทุกวันอาทิตย์ เวลา 00.00น.)
                              </span>
                              <br></br>
                            </div>

                            {/* <span className="-title">
                            คุณได้เปอเซนต์  <span className="-title" style={{ color: "#d9ad70" }}  >{cashoutSet != null ? cashoutSet.percent : "0"}%</span> จากยอดเสีย ยอดได้รับ <span className="-title" style={{ color: "#d9ad70" }}  > {tblprofile.AffiliateCash}  </span> บาท
                          </span> */}
                            <br></br>
                            {(cashoutSet != null) ? tblprofile.AffiliateCash <= cashoutSet.minwithdraw ? "" : <a
                              onClick={(e) => {
                                this.opencliaminvite(e, 'bonusrankaccount')
                              }}
                              data-container="#accountModal"
                              style={{ marginTop: "10px", cursor: "pointer" }}
                              className="text-white js-account-approve-aware btn -btn-deposit">
                              <div className="f-7">เคลม</div>
                            </a> : ""}
                            <div className="-outer-history-wrapper" style={{ marginTop: "10px" }} >
                              <div className="x-bill-history-container">
                                <div
                                  className="wg-container wg-container__wg_bill_history wg--loaded"
                                  data-widget-name="wg_bill_history"
                                  data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                                  data-widget-user-options='{"page":1}'
                                >
                                  <div className="wg-content" style={{ display: (this.state.useradvice.length === 0) ? "" : "none" }}>
                                    <div className="-no-result-wrapper mt-3">
                                      <div className="-heading-title">
                                        <i className="fas fa-times-circle -icon" />
                                        <div className="-title">ไม่พบข้อมูล</div>
                                        <div className="-sub-title" style={{ color: "black" }}>คุณยังไม่มีข้อมูลการชวนเพื่อนค่ะ</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="wg-content " style={{ display: (this.state.useradvice.length === 0) ? "none" : "" }}>
                                    <table className="table table-borderless table-striped">
                                      <tbody>
                                        {this.state.useradvice.map((listitem, i) => (
                                          <tr key={i} id={listitem.tid}>
                                            <td className="-description-body-wrapper">
                                              <div className="-title-wrapper">

                                                {i + 1 + "."} <span className="-title" style={{ marginLeft: "0px" }}> {listitem.playerid}
                                                  {(listitem.checked !== 1) ? "" : <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                    className="-ic"
                                                    style={{ marginLeft: "5px", marginBottom: "3px" }}
                                                    width={"20px"}
                                                    alt=""
                                                  />}
                                                </span>
                                              </div>
                                            </td>
                                            <td className="-transaction-body-wrapper">
                                              <div className="-datetime">{moment(listitem.DateCreate).format('DD/MM/YY') + " - " + moment(listitem.DateCreate).format('HH:mm') + " น."} </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="js-profile-account-modal -layout-account" style={{ display: "none" }}
                    id="couponaccount"
                  >
                    <div className="x-account-coupon">
                      <div
                        data-animatable="fadeInModal"
                        className="-coupon-container  fadeInModal"

                      >
                        <h3 className="x-title-modal mx-auto text-center ">ใช้คูปอง</h3>
                        <div className="-coupon-member-detail" style={{ marginTop: "50px" }}>
                          <div className="-coupon-box">
                            <img
                              src="/build/web/igame-index-lobby-wm/img/ic-coupon.png"
                              alt="คูปองเล่นคาสิโนออนไลน์ โบนัสสล็อตออนไลน์"
                              className="img-fluid -ic-coupon m-auto"
                              width={220}
                              height={94}
                            />
                          </div>
                        </div>
                        <div className="-form-coupon-container" >

                          <div className="my-4 -x-input-icon">
                            <img
                              src="/build/web/igame-index-lobby-wm/img/ic-coupon-input.png"
                              className="-icon"
                              alt="icon-coupon"
                            />
                            <input
                              type="text"
                              onChange={this.onChangex}
                              id="couponkey"
                              name="couponkey"
                              required="required"
                              className="x-coupon-input form-control"
                              placeholder="รหัสคูปอง"
                            />
                          </div>
                          <div className="-btn-container">
                            <button
                              onClick={(e) => {
                                this.couponredeem(e)
                              }}
                              type="submitx" className="btn  btn-primary">
                              ยืนยัน
                            </button>
                          </div>
                          <input
                            type="hidden"
                            id="coupon__token"
                            name="coupon[_token]"
                            defaultValue="kg4A5PhTS8tsqjpMl7ef3x1ECaVkawFij6MAsvN55G0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="js-profile-account-modal -layout-account"
                    id="proaccount"
                  >
                    <h3 className="x-title-modal mt-0 mb-3 mx-auto text-center">
                      โปรโมชั่นที่เข้าร่วม
                    </h3>
                    <div className="-outer-history-wrapper">
                      <div className="x-bill-history-container">
                        <div
                          className="wg-container wg-container__wg_bill_history wg--loaded"
                          data-widget-name="wg_bill_history"
                          data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                          data-widget-user-options='{"page":1}'
                        >
                          <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "" : "none" }}>
                            <div className="-no-result-wrapper mt-3">
                              <div className="-heading-title">
                                <i className="fas fa-times-circle -icon" />
                                <div className="-title">ไม่พบข้อมูล</div>
                                <div className="-sub-title">คุณยังไม่มีข้อมูลการทำรายการค่ะ</div>
                              </div>
                            </div>
                          </div>
                          <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "none" : "" }}>
                            <table className="table table-borderless table-striped">
                              <tbody>
                                {proderedeemlist.map((listitem, i) => (
                                  <tr key={i} id={i}>
                                    <td className="-description-body-wrapper">
                                      <div className="-title-wrapper">
                                        <span className="-title">{listitem.proname}</span>
                                        <span style={{ display: (listitem.status === 1) ? "" : "none", margin: "5px" }}>
                                          <a href={() => false} style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              this.cancelredeempromotion(e, listitem)
                                            }}
                                            className="-link-change-password" ><u>ยกเลิก</u></a>
                                        </span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === 1) ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <span className="-state-title">รอดำเนินการ</span>
                                        <span className="-state-title -short">รอดำเนินการ  </span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === 4) ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          alt=""
                                        />
                                        <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                        <span className="-state-title -short">ยกเลิก (โดยผู้เล่น)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === 3) ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                          className="-ic"
                                          alt=""
                                        />
                                        <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                        <span className="-state-title -short">ไม่สำเร็จ (โดยระบบ)</span>
                                      </div>
                                      <div className="-state-wrapper" style={{ display: (listitem.status === 2) ? "" : "none" }}>
                                        <span className="-state-text">สถานะ : </span>
                                        <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                          className="-ic"
                                          alt=""
                                        />
                                        <span className="-state-title">สำเร็จ</span>
                                        <span className="-state-title -short">สำเร็จ</span>
                                      </div>
                                    </td>
                                    <td className="-transaction-body-wrapper">
                                      <div className="-amount -deposit">{listitem.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} เครดิต</div>
                                      <div className="-datetime">{moment(listitem.datecreate).format('DD/MM/YY') + " - " + moment(listitem.datecreate).format('HH:mm') + " น."} </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="js-profile-account-modal -layout-account"
                    id="bonusaccount"
                  >
                    <h3 className="x-title-modal mx-auto text-center mt-0 mb-2">
                      รับโบนัสยอดเสีย {cashoutSet.percent} %
                    </h3>
                    <div className="x-promotion-return-by-user-container">
                      <div className="-group-round-container -no-data">
                        <div className="-date-range-container text-center">
                          ยอดโบนัสระหว่างวันที่ {moment(cashoutSet.LastStartDate).format('DD') + " " + this.showClockRealTime(cashoutSet.LastStartDate) + " - " + moment(cashoutSet.LastEndDate).format('DD') + " " + this.showClockRealTime(cashoutSet.LastStartDate) + "  "}
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary -promotion-return-btn"
                          onClick={(e) => {
                            this.opencliamcashout(e, 'bonusrankaccount')
                          }}
                        >
                          {tblprofile.Cashtotal > 0 ? <span className="-text-btn">รับยอดเสีย</span> : <span className="-text-btn">ไม่เข้าเงื่อนไข</span>}

                        </button>
                      </div>
                      <div className="-description-container">
                        {tblprofile.Cashtotal > 0 ? <div>คุณได้รับยอดเสีย {tblprofile.Cashtotal} บาท</div> : <div>คุณไม่เข้าเงื่อนไขการรับโบนัส</div>}

                        <div>
                          <span className="-text-description">
                            โปรดอ่านเงื่อนไขการเข้าร่วม
                          </span>
                          ด้านล่างค่ะ
                        </div>
                      </div>
                      <div className="-condition-container">
                        <div className="-condition-title">
                          <u>โปรดอ่านเงื่อนไข</u>
                        </div>
                        <div className="x-promotion-content">
                          <h2>
                            <strong>
                              เล่นได้ให้เพิ่ม เล่นเสียให้คืน {cashoutSet.percent} % ทุกสัปดาห์
                            </strong>
                          </h2>
                          ► คำนวณยอดใน 1 สัปดาห์ (ตั้งแต่ จันทร์ 00:01 น. ถึง วันอาทิตย์
                          23:59 น.)
                          <br />
                          ► ต้องมียอดเทิร์นโอเวอร์ 5 เท่าของเงินฝากภายในสัปดาห์
                          <br />
                          ► โบนัสจะได้รับทุกวันจันทร์ สามารถกดรับโบนัสได้ที่หน้าเว็บ
                          <br />
                          ► โบนัสที่ได้รับไม่ต้องทำเทิร์นโอเวอร์ ถอนได้ทันที!
                          <br />
                          ► มียอดได้-เสียมากกว่า 500 บาทต่อสัปดาห์ จึงจะได้รับโบนัส
                          5%
                          <br />
                          ► เมื่อรับโปรโมชั่น เครดิตจะมีอายุการใช้งาน 7 วัน
                          จากนั้นเครดิตคืนยอดเสียจะถูกปรับเป็น 0&nbsp;
                          <br />
                          &nbsp;
                          <a href="/term-and-condition">
                            เงื่อนไขและกติกาพื้นฐานจะถูกนำมาใช้กับโปรโมชั่นนี้
                          </a>
                        </div>
                      </div>
                      <div className="my-3">
                        <div className="x-admin-contact -no-fixed">
                          <span className="x-text-with-link-component">
                            <label className="-text-message ">ติดปัญหา</label>
                            <a
                              href={this.state.linelink}
                              className="-link-message "
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <u>ติดต่อฝ่ายบริการลูกค้า</u>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="js-profile-account-modal -layout-account"
                    id="bonusrankaccount"
                  >
                    <h3 className="x-title-modal mx-auto text-center mt-0 mb-2">
                      ขอรับโบนัส Rank
                    </h3>
                    <div className="x-promotion-return-by-user-container">
                      <div className="-description-container">
                        <div>
                          <span className="-text-description">
                            โปรดอ่านเงื่อนไขการเข้าร่วม
                          </span>
                          ด้านล่างค่ะ
                        </div>
                      </div>
                      <div className="-condition-container" style={{ height: "450px", width: "480px" }}>
                        <div className="x-promotion-content">
                          <table className="table table-borderless table-striped">
                            <tbody>
                              {this.state.tblrankall.map((listitem, i) => (
                                <tr key={i} id={listitem.no}>
                                  <td className="-description-body-wrapper">
                                    <img
                                      width={"100px"}
                                      className="img-fluid -profile-image"
                                      src={"/build/rank/" + listitem.image}
                                      alt=""
                                    />
                                    <div className="-title-wrapper">
                                      <p><span className="-title">{listitem.name}</span>        </p>
                                    </div>
                                    <div className="-state-wrapper">
                                      <span className="-text-description">{"เติม " + listitem.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท {(listitem.no === 1) ? "" : "โบนัส " + listitem.claimamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " บาท ชวนเพื่อน " + listitem.advicepercent + "%"} </span>
                                    </div>
                                  </td>
                                  <td className="-transaction-body-wrapper">
                                    {
                                      (listitem.no === 1) ? "" :
                                        (listitem.lowamount <= rankpoint && !listitem.checked) ? <div> <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                          className="-ic"
                                          width={"30px"}
                                          alt=""
                                        /><span className="-state-title" style={{ marginLeft: "10px" }}>สำเร็จ</span></div> :
                                          (!listitem.checked) ? "ยังไม่ผ่านเงื่อนไข" : <a href={() => false} style={{ cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit active" onClick={(e) => {
                                            this.redeemrank(e, listitem)
                                          }}><div className="f-7">Cliam</div></a>}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="my-3">
                        <div className="x-admin-contact -no-fixed">
                          <span className="x-text-with-link-component">
                            <label className="-text-message ">ติดปัญหา</label>
                            <a
                              href={this.state.linelink}
                              className="-link-message "
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <u>ติดต่อฝ่ายบริการลูกค้า</u>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* หน้าข้อมูลบัญชี */}
          <div
            className="x-modal modal -v2 -scrollable -no-button js-modal-scrollable"
            id="historyModal"
            tabIndex={-1}
            role="dialog"
            data-loading-container=".modal-body"
            data-container="#historyModal .modal-body"
            style={{ display: "none" }}
            aria-hidden="true"
          >
            <div
              className="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable "
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal -v2 mt-0">ประวัติการทำรายการ</h3>
                </div>
                <div className="modal-body -modal-body">
                  <div className="-outer-history-wrapper">
                    <div className="x-bill-history-container">
                      <div
                        className="wg-container wg-container__wg_bill_history wg--loaded"
                        data-widget-name="wg_bill_history"
                        data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                        data-widget-user-options='{"page":1}'
                      >
                        <div className="wg-content" style={{ display: (this.state.historylist.length === 0) ? "" : "none" }}>
                          <div className="-no-result-wrapper mt-3">
                            <div className="-heading-title">
                              <i className="fas fa-times-circle -icon" />
                              <div className="-title">ไม่พบข้อมูล</div>
                              <div className="-sub-title">คุณยังไม่มีข้อมูลการทำรายการค่ะ</div>
                            </div>
                          </div>
                        </div>
                        <div className="wg-content" style={{ display: (this.state.historylist.length === 0) ? "none" : "" }}>
                          <table className="table table-borderless table-striped">
                            <tbody>
                              {this.state.historylist.map((listitem, i) => (
                                <tr key={i} id={listitem.tid}>
                                  <td className="-description-body-wrapper">
                                    <div className="-title-wrapper">
                                      <span className="-title">{(listitem.type === "DE") ? "ฝากเงิน" : "ถอนเงิน"}</span>
                                      <span style={{ display: (listitem.status === "0") ? "" : "none", margin: "5px" }}>
                                        <a href={() => false} style={{ cursor: "pointer" }} onClick={(e) => {
                                          this.cancelwithdraw(e, listitem)
                                        }}
                                          className="-link-change-password" ><u>ยกเลิก</u></a>
                                      </span>
                                    </div>
                                    <div className="-state-wrapper" style={{ display: (listitem.status === "2") ? "" : "none" }}>
                                      <span className="-state-text">สถานะ : </span>
                                      <i className="fas fa-spinner fa-spin"></i>
                                      <span className="-state-title">กำลังดำเนินการ</span>
                                      <span className="-state-title -short">กำลังดำเนินการ</span>
                                    </div>
                                    <div className="-state-wrapper" style={{ display: (listitem.status === "0") ? "" : "none" }}>
                                      <span className="-state-text">สถานะ : </span>
                                      <i className="fas fa-spinner fa-spin"></i>
                                      <span className="-state-title">รอดำเนินการ</span>
                                      <span className="-state-title -short">รอดำเนินการ  </span>

                                    </div>
                                    <div className="-state-wrapper" style={{ display: (listitem.status === "4") ? "" : "none" }}>
                                      <span className="-state-text">สถานะ : </span>
                                      <img
                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                        className="-ic"
                                        alt=""
                                      />
                                      <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                      <span className="-state-title -short">ยกเลิก (โดยผู้เล่น)</span>
                                    </div>
                                    <div className="-state-wrapper" style={{ display: (listitem.status === "5") ? "" : "none" }}>
                                      <span className="-state-text">สถานะ : </span>
                                      <img
                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                        className="-ic"
                                        alt=""
                                      />
                                      <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                      <span className="-state-title -short">ไม่สำเร็จ (โดยระบบ)</span>
                                    </div>
                                    <div className="-state-wrapper" style={{ display: (listitem.status === "1") ? "" : "none" }}>
                                      <span className="-state-text">สถานะ : </span>
                                      <img
                                        src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                        className="-ic"
                                        alt=""
                                      />
                                      <span className="-state-title">สำเร็จ</span>
                                      <span className="-state-title -short">สำเร็จ</span>
                                    </div>

                                  </td>
                                  <td className="-transaction-body-wrapper">
                                    <div className={(listitem.type === "DE") ? "-amount -deposit" : "-amount -withdraw"}>{listitem.amount} บาท</div>
                                    <div className="-datetime">{moment(listitem.createdate).format('DD/MM/YY') + " - " + moment(listitem.createdate).format('HH:mm') + " น."} </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* <Spinwheels /> */}
          {/* <div
          className="x-modal modal -hello-popup show"
          id="promotionSuggestionModalxxx"
          tabIndex={-1}
          role="dialog"
          data-loading-container=".js-modal-content"
          data-ajax-modal-always-reload="true"
          style={{ paddingRight: 6 }}
          aria-modal="true"
        >
          <div
            className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -no-fixed-button"
            role="document"
          >
            <div className="modal-content -modal-content">
              <button
                type="button"
                onClick={(e) => {
                  this.closemodal(e)
                }}
                className="close f-1 "
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-times" />
              </button>
              <div className="modal-header border-bottom-0">
                <img
                  alt="โปรโมชั่น Welcome Back ยินดีต้อนรับกลับ"
                  className="-logo js-modal-header-image fadeInModal animated"
                  width={700}
                  height={300}
                  data-animatable="fadeInModal"
                  data-offset={0}
                  src="/build/web/igame-index-lobby-wm/img/hello-popup-title.png"
                />
              </div>
              <div className="modal-body -modal-body">
                <div className="js-modal-content">
                  <div className="x-promotion-hello-modal-body">
                    <div className="-promotion-list-wrapper">
                      {this.state.tblPopupMenu1.map((listitem, i) => (
                        <div className="-promotion-list-item ">
                          <a
                            href="#0"
                            className="btn -btn js-promotion-apply fadeIn animated"
                            onClick={(e) => {
                              this.redeemlink(e, listitem.Popuplink)
                            }}
                            data-offset={0}
                          >
                            <div className="-badge-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/hello-badge-bg.png"
                                className="-img"
                                alt="ยินดีต้อนรับ สู่เว็บอันดับ 1"
                                width={130}
                                height={33}
                              />
                              <span className="-title">แนะนำ</span>
                            </div>
                            <img
                              src={listitem.img}
                              className="-thumbnail"
                              alt="ยินดีต้อนรับ สู่เว็บอันดับ 1 ฝาก  1,000 รับ 100"
                              width={140}
                              height={140}
                            />
                            <div className="-content">
                              <span className="-text -deposit">{listitem.PopupName}</span>
                              <span className="-text -description">{listitem.Popuptext}</span>
                              <span className="-text -bonus">
                                <img
                                  src="/build/web/shared/img/hello-stars.png"
                                  className="-ic-star"
                                  alt="ยินดีต้อนรับ สู่เว็บอันดับ 1"
                                />
                                <i>{listitem.Amount}</i>
                              </span>
                            </div>
                            <img
                              src="/build/web/shared/img/hello-promotion-light-top.png"
                              className="-light -top"
                              alt="ยินดีต้อนรับ สู่เว็บอันดับ 1"
                            />
                            <img
                              src="/build/web/shared/img/hello-promotion-light-bottom.png"
                              className="-light -bottom"
                              alt="ยินดีต้อนรับ สู่เว็บอันดับ 1"
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                    <ul
                      className="list-group list-group-horizontal -service-list-container fadeInUp animated"
                      data-animatable="fadeInUp"
                      data-offset={0}
                    >
                      <li className="list-group-item -service-list-item">
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-dismiss="modal"
                          data-target="#registerModal"
                        >
                          <img
                            src="/build/web/igame-index-lobby-wm/img/register-icon-first-step.png"
                            className="-thumbnail"
                            alt="ยินดีต้อนรับ สู่เว็บอันดับ 1"
                            width={100}
                            height={100}
                          />
                          <span className="-text">
                            สมัครเลย โปรเพียบ
                          </span>
                        </a>
                      </li>
                      <li className="list-group-item -service-list-item">
                        <img
                          src="/build/web/igame-index-lobby-wm/img/hello-return.png"
                          className="-thumbnail"
                          alt="ยินดีต้อนรับ สู่เว็บอันดับ 1"
                          width={100}
                          height={100}
                        />
                        <span className="-text">
                          เล่นเสียให้คืนทุกวันพุธ <br /> สูงสุด 5%
                        </span>
                      </li>
                    </ul>
                    <div className="-effect -item-1">
                      <img
                        src="/build/web/igame-index-lobby-wm/img/welcome-back-effect-1.png"
                        className="-img fadeIn animated"
                        alt="รูปเหรียญโปรโมชั่นต้อนรับกลับ"
                        data-animatable="fadeIn"
                        data-offset={0}
                        data-delay={100}
                      />
                    </div>
                    <div className="-effect -item-2">
                      <img
                        src="/build/web/igame-index-lobby-wm/img/welcome-back-effect-2.png"
                        className="-img fadeIn animated"
                        alt="รูปเหรียญโปรโมชั่นต้อนรับกลับ"
                        data-animatable="fadeIn"
                        data-offset={0}
                        data-delay={200}
                      />
                    </div>
                    <div className="-effect -item-3">
                      <img
                        src="/build/web/igame-index-lobby-wm/img/welcome-back-effect-3.png"
                        className="-img fadeIn animated"
                        alt="รูปเหรียญโปรโมชั่นต้อนรับกลับ"
                        data-animatable="fadeIn"
                        data-offset={0}
                        data-delay={300}
                      />
                    </div>
                  </div>
                </div>

                <div className="-outer-wrapper" id='checkboxacc'  >
                  <label className="containerh">ไม่แสดงอีกภายในวันนี้
                    <input type="checkbox" checked={this.state.checkboxcookie} onClick={(e) => this.recheckbox()} />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div> */}







          <div
            id="ProDetailModal1"
            tabIndex={-1}
            className="modal x-modal -promotion-detail-modal promotion-detail-modal-149 show"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-mobile"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-body -modal-body" >
                  <div className="container">
                    <div className="col-12 mt-4">
                      <div className="x-page-title-component -midsize">
                        <div className="-inner-wrapper">
                          <div className="-title">โปรโมชั่นเพิ่ม Rank </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column" >
                    <div className="-real-content" >
                      <div className="x-card card -card-promotion-detail ">
                        <div className="card-body" >
                          <table className="table table-borderless table-striped">
                            <tbody>
                              {this.state.tblrankall.map((listitem, i) => (
                                <tr key={i} id={listitem.no}>
                                  <td className="-description-body-wrapper">
                                    <img
                                      width={"100px"}
                                      className="img-fluid -profile-image"
                                      src={"/build/rank/" + listitem.image}
                                      alt=""
                                    />
                                    <div className="-title-wrapper">
                                      <p><span className="-title">{listitem.name}</span>        </p>
                                    </div>
                                    <div className="-state-wrapper">
                                      <span className="-text-description">{"เติม " + listitem.lowamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาทขึ้นไป {(listitem.no === 1) ? "" : "โบนัส " + listitem.claimamount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " บาท"} </span>
                                    </div>
                                  </td>
                                  <td className="-transaction-body-wrapper">
                                    {
                                      (listitem.no === 1) ? "" :
                                        (listitem.lowamount <= rankpoint && !listitem.checked) ? <div> <img
                                          src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                          className="-ic"
                                          width={"30px"}
                                          alt=""
                                        /><span className="-state-title" style={{ marginLeft: "10px" }}>สำเร็จ</span></div> :
                                          (!listitem.checked) ? "ยังไม่ผ่านเงื่อนไข" : <a href={() => false} style={{ cursor: "pointer" }} className="text-white js-account-approve-aware btn -btn-deposit active" onClick={(e) => {
                                            this.redeemrank(e, listitem)
                                          }}><div className="f-7">Cliam</div></a>}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                    <a
                      href="#deposit-choose-promotion"
                      className="js-account-approve-aware btn -back-btn"
                      data-toggle="modal"
                      // data-target="#depositChoosePromotionModal"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-arrow-left" />
                      <div className="f-6 -text">ย้อนกลับ</div>
                    </a>
                    <div className="mx-3 mb-3">
                      <div className="x-admin-contact -no-fixed">
                        <span className="x-text-with-link-component">
                          <label className="-text-message ">ติดปัญหา</label>
                          <a
                            href={this.state.linelink}
                            className="-link-message "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* รายละเอียดโปร */}
          <div
            id="ProDetailModal"
            tabIndex={-1}
            className="modal x-modal -promotion-detail-modal promotion-detail-modal-149 show"
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-mobile"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-body -modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 mt-4">
                        <div className="x-page-title-component -midsize">
                          <div className="-inner-wrapper">
                            <div className="-title">โปรโมชั่น</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column" >
                    {(prodetail.length === 0) ? <div className="x-card card -card-promotion-detail ">
                      <div className="card-body">
                        <div className="-title">โปรโมชั่นยังไม่ได้เปิดใช้งาน</div>
                        <div className="-img-container">
                          <img
                            src="/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png"
                            alt="โปรโมชั่นยังไม่ได้เปิดใช้งาน"
                            className="-img-promotion img-fluid"
                            width={398}
                            height={143}
                          />
                        </div>
                      </div>
                    </div>
                      :
                      <div className="-real-content" style={{ fontSize: "1em !important" }}>
                        <div className="x-card card -card-promotion-detail ">
                          <section
                            dangerouslySetInnerHTML={{ __html: prodetail.detail }}
                          />
                          <div className="card-footer">
                            <button
                              className="btn "
                              style={{ fontWeight: "300", background: "radial-gradient(100% 100% at 50% 0, #f9cf95 0, #c69a5d 100%)" }}
                              onClick={(e) => this.redeempro(e, prodetail.no)}
                            >
                              <span>รับโปรโมชั่น</span>
                            </button>
                            <button id="opendeposit" onClick={(e) => this.confirmdeposit(e, 4)} className="btn -btn -get-promotion-btn  " style={{ display: "none" }} data-toggle="modal" data-target="#deposittruemoneyModal" data-dismiss="modal"  >
                            </button>
                          </div>
                        </div>
                      </div>}
                    <a
                      href="#deposit-choose-promotion"
                      className="js-account-approve-aware btn -back-btn"
                      data-toggle="modal"
                      data-target="#depositChoosePromotionModal"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-arrow-left" />
                      <div className="f-6 -text">ย้อนกลับ</div>
                    </a>
                    <div className="mx-3 mb-3">
                      <div className="x-admin-contact -no-fixed">
                        <span className="x-text-with-link-component">
                          <label className="-text-message ">ติดปัญหา</label>
                          <a
                            href={this.state.linelink}
                            className="-link-message "
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>ติดต่อฝ่ายบริการลูกค้า</u>
                          </a>
                          <button id="openpopup"
                            style={{ display: "none" }} data-toggle="modal" data-target="#promotionSuggestionModalxx" data-dismiss="modal"  >
                            <button id="depositChoosePromo" style={{ display: "none" }} data-toggle="modal" data-target="#depositChoosePromotionModalxx" data-dismiss="modal"  ></button>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* รายละเอียดโปร */}
          {/* Alert */}
          {/* <div className="x-right-sidebar-container">
            <div className="x-notification-entrance-container js-notification-entry">
              <a
                href="#notificationCenter"
                className="-link-wrapper "
                data-toggle="modal"
                data-target="#notificationCenterModal"
              >
                <div className="-img-wrapper">
                  <img
                    src="/build/web/igame-index-lobby-wm/img/notification-ic-bell.png"
                    className="-img"
                    alt=""
                    width={40}
                    height={44}
                  />
                </div>
              </a>
            </div>
          </div> */}
          {/* Alert */}
          {/* Alert List */}

          <div
            className="x-modal modal show"
            id="notificationCenterModal"
            tabIndex={-1}
            role="dialog"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable "
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <div className="-outer-wrapper">
                    <img
                      src="/build/web/igame-index-lobby-wm/img/notification-title-bg.png"
                      className="-title-bg"
                      alt=""
                      width={320}
                      height={64}
                    />
                    <h3 className="x-title-modal">
                      <img
                        src="/build/web/igame-index-lobby-wm/img/notification-ic-bell.png"
                        className="-ic-title"
                        alt=""
                        width={40}
                        height={40}
                      />
                      <span className="-text">กระดานแจ้งเตือน</span>
                    </h3>
                  </div>
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-notification-center-render-container">
                    <div className="x-notification-history-list-container js-infinite-scroll-list-container">
                      <div className="wg-container wg-container__wg_inbox wg--loaded">
                        <div className="wg-content">
                          {/* <div className="x-notification-list-item-wrapper -global ">
                          <img
                            src="/build/web/igame-index-lobby-wm/img/notification-ic-alert.png"
                            className="-ic-img"
                            alt=""
                            width={70}
                            height={70}
                          />
                          <div className="-details-wrapper">
                            <div className="-title">ขณะนี้ธนาคารขัดข้อง</div>
                            <p className="-description">ขออภัยในความไม่สะดวกค่ะ</p>
                            <span className="-datetime">2 เดือนที่แล้ว</span>
                          </div>
                          <div className="-actions-wrapper">
                            <button
                              type="button"
                              className="btn -delete-btn js-notification-remove-item"
                              aria-label="Remove item 3699921"
                              data-remove-url="/account/_ajax_/notifitaion/3699921/remove"
                            >
                              <i className="fas fa-trash-alt" />
                            </button>
                          </div>
                        </div>
                        <div className="x-notification-list-item-wrapper -global ">
                          <img
                            src="/build/web/igame-index-lobby-wm/img/notification-ic-alert.png"
                            className="-ic-img"
                            alt=""
                            width={70}
                            height={70}
                          />
                          <div className="-details-wrapper">
                            <div className="-title">ยิ่งชวนยิ่งได้</div>
                            <p className="-description">
                              แนะนำเพื่อนมาเล่น รับสูงสุด 300 บาท ชวนครบ 10 คน
                              รับเพิ่มทันทีอีก 1000 บาท
                              สอบถามเพิ่มเติมได้ที่ฝ่ายบริการ @betsup
                            </p>
                            <span className="-datetime">3 เดือนที่แล้ว</span>
                          </div>
                          <div className="-actions-wrapper">
                            <button
                              type="button"
                              className="btn -delete-btn js-notification-remove-item"
                              aria-label="Remove item 3110659"
                              data-remove-url="/account/_ajax_/notifitaion/3110659/remove"
                            >
                              <i className="fas fa-trash-alt" />
                            </button>
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="-delete-all-btn js-notification-remove-all"
                      data-remove-url="/account/_ajax_/notifitaion/remove-all"
                    >
                      <i className="fas fa-trash-alt" />
                      <span className="-text">ลบทั้งหมด</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Alert List */}
          {/* เลือกโปรก่อนเล่นเกม */}
          <div
            className="x-modal modal show"
            id="gameproselect"
            tabIndex={-1}
            role="dialog"
            style={{ paddingRight: 6 }}
            aria-modal="true"
          >
            <div
              className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable -modal-deposit"
              role="document"
            >
              <div className="modal-content -modal-content">
                <button
                  type="button"
                  className="close f-1 "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times" />
                </button>
                <div className="modal-header -modal-header">
                  <h3 className="x-title-modal m-auto">เข้าสู่เกม</h3>
                </div>
                <div className="showgamepro">
                  <img src="/build/admin/img/sexy-bac/ezc-sexy-bac-direct-COSPLAY.webp?v=5" alt="" />
                </div>
                <div className="modal-body -modal-body">
                  <div className="x-deposit-form">
                    <div className="row -deposit-container">
                      <div
                        data-animatable="fadeInModal"
                        className="col-lg order-lg-2 -form order-0  fadeInModal"
                      >
                        <div className="-deposit-form-inner-wrapper d-block ">
                          <div
                            noValidate=""
                            name="deposit"
                            method="post"
                            data-container="#depositModal"
                          >
                            <div className="-modal-body-top">
                              <div className="text-center d-flex flex-column">
                                <div className="m-auto"></div>
                              </div>
                              <div className="-x-input-icon x-input-operator mb-4 flex-column">
                                <button
                                  type="button"
                                  className="-icon-left -btn-icon js-adjust-amount-by-operator"
                                  data-operator="-"
                                  data-value={10}
                                >
                                  <i className="fas fa-minus-circle" />
                                </button>
                                <input
                                  type="text"
                                  id="deposit_amount"
                                  name="deposit[amount]"
                                  required="required"
                                  pattern="[0-9]*"
                                  className="x-form-control -text-big text-center js-deposit-input-amount form-control"
                                  placeholder="เงินฝากขั้นต่ำ 10"
                                  inputMode="text"
                                />
                                <button
                                  type="button"
                                  className="-icon-right -btn-icon js-adjust-amount-by-operator"
                                  data-operator="+"
                                  data-value={10}
                                >
                                  <i className="fas fa-plus-circle" />
                                </button>
                              </div>
                            </div>
                            {/* เลือกโปร ก่อนเล่นเกม */}
                            <div className="selectprogame">
                              {/* ไม่รับโบนัส */}
                              <input
                                type="radio"
                                name="choiceprogame"
                                id="choose-1"
                                defaultValue={1}
                              />
                             
                              {/* ไม่รับโบนัส */}
                              {/* โปร 1 */}
                              <input
                                type="radio"
                                name="choiceprogame"
                                id="choose-2"
                                defaultValue={2}
                              />
                              <label htmlFor="choose-2">
                              </label>
                              {/* โปร 1 */}
                              {/* โปร 2 */}
                              <input
                                type="radio"
                                name="choiceprogame"
                                id="choose-3"
                                defaultValue={3}
                              />
                              <label htmlFor="choose-3">
                                <img src="" alt="" />
                              </label>
                              {/* โปร 2 */}
                            </div>
                            {/* เลือกโปร ก่อนเล่นเกม */}
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn  btn-primary my-0 my-lg-3"
                              >
                                ยืนยัน
                              </button>
                            </div>
                            <input
                              type="hidden"
                              id="deposit__token"
                              name="deposit[_token]"
                              defaultValue="d8iYDTklPETlCIEBA5CM4b1J9xZS1ZFfMKUoJ-c21fE"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* เลือกโปรก่อนเล่นเกม */}
          <div className="d-lg-none">
            <div
              className="x-modal modal "
              id="bookmarkModal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
              data-loading-container=".js-modal-content"
              data-ajax-modal-always-reload="true"
            >
              <div
                className="modal-dialog -modal-size modal-dialog-centered modal-dialog-scrollable         -no-fixed-button
                  "
                role="document"
              >
                <div className="modal-content -modal-content">
                  <button
                    type="button"
                    className="close f-1 "
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fas fa-times" />
                  </button>
                  <div className="modal-header -modal-header">
                    <h3 className="x-title-modal d-inline-block m-auto">
                      <span>Bookmark</span>
                    </h3>
                  </div>
                  <div className="modal-body -modal-body">
                    <div className="x-bookmark-modal-container">
                      <nav>
                        <div
                          className="nav nav-tabs x-bookmark-tabs-header-wrapper"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            className="nav-link active"
                            id="nav-android-tab"
                            data-toggle="tab"
                            href="#nav-android"
                            role="tab"
                            aria-controls="nav-android"
                            aria-selected="true"
                          >
                            Android
                          </a>
                          <a
                            className="nav-link "
                            id="nav-ios-tab"
                            data-toggle="tab"
                            href="#nav-ios"
                            role="tab"
                            aria-controls="nav-ios"
                            aria-selected="true"
                          >
                            iOS
                          </a>
                        </div>
                      </nav>
                      <div
                        className="tab-content x-bookmark-tabs-content-wrapper"
                        id="nav-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="nav-android"
                          role="tabpanel"
                          aria-labelledby="nav-android-tab"
                        >
                          <div
                            className="-slide-wrapper -bookmark-slider-for-android"
                            data-slickable='{"arrows":false,"dots":true,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-nav-android"}'
                          >
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-android-1.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-android-2.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-android-3.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-android-4.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                          </div>
                          <div
                            className="-slide-wrapper -bookmark-slider-nav-android"
                            data-slickable='{"arrows":false,"dots":false,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-for-android"}'
                          >
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">1</div>
                                <div className="-description">
                                  เข้า Google Chrome แล้ว
                                  <br /> Search "Secret789-VIP" เข้าสู่หน้าเว็บ
                                </div>
                              </div>
                            </div>
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">2</div>
                                <div className="-description">
                                  กดที่ <i className="fas fa-ellipsis-v" />
                                  <br /> เลือก "เพิ่มลงในหน้าจอหลัก"
                                </div>
                              </div>
                            </div>
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">3</div>
                                <div className="-description">
                                  กด "เพิ่ม" ทางลัดเข้าสู่เกมส์
                                  <br /> ลงในหน้าจอโฮม
                                </div>
                              </div>
                            </div>
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">4</div>
                                <div className="-description">
                                  ตรวจสอบหน้า
                                  <br /> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade "
                          id="nav-ios"
                          role="tabpanel"
                          aria-labelledby="nav-ios-tab"
                        >
                          <div
                            className="-slide-wrapper -bookmark-slider-for-ios"
                            data-slickable='{"arrows":false,"dots":true,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-nav-ios"}'
                          >
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-ios-1.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-ios-2.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-ios-3.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                            <div className="-slide-inner-wrapper">
                              <img
                                src="/build/web/igame-index-lobby-wm/img/bookmark-ios-4.png"
                                className="-img"
                                alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย"
                              />
                            </div>
                          </div>
                          <div
                            className="-slide-wrapper -bookmark-slider-nav-ios"
                            data-slickable='{"arrows":false,"dots":false,"slidesToShow":1,"fade":true,"infinite":true,"autoplay":false,"asNavFor":".-bookmark-slider-for-ios"}'
                          >
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">1</div>
                                <div className="-description">
                                  เข้า Google Chrome แล้ว
                                  <br /> Search "Secret789-VIP" เข้าสู่หน้าเว็บ
                                </div>
                              </div>
                            </div>
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">2</div>
                                <div className="-description">
                                  กดที่ <i className="fas fa-ellipsis-v" />
                                  <br /> เลือก "เพิ่มลงในหน้าจอหลัก"
                                </div>
                              </div>
                            </div>
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">3</div>
                                <div className="-description">
                                  กด "เพิ่ม" ทางลัดเข้าสู่เกมส์
                                  <br /> ลงในหน้าจอโฮม
                                </div>
                              </div>
                            </div>
                            <div className="-slide-inner-wrapper px-3 pt-3">
                              <div className="-content-wrapper">
                                <div className="-number">4</div>
                                <div className="-description">
                                  ตรวจสอบหน้า
                                  <br /> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <script id="b-loading" type="text/template">
            <div className="x-dice-container py-5 m-auto d-flex">
              <div id="dice" className="mx-auto">
                <div className="side front">
                  <div className="dot center bg-danger"></div>
                </div>
                <div className="side front inner"></div>
                <div className="side top">
                  <div className="dot dtop dleft"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side top inner"></div>
                <div className="side right">
                  <div className="dot dtop dleft"></div>
                  <div className="dot center"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side right inner"></div>
                <div className="side left">
                  <div className="dot dtop dleft"></div>
                  <div className="dot dtop dright"></div>
                  <div className="dot dbottom dleft"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side left inner"></div>
                <div className="side bottom">
                  <div className="dot center"></div>
                  <div className="dot dtop dleft"></div>
                  <div className="dot dtop dright"></div>
                  <div className="dot dbottom dleft"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side bottom inner"></div>
                <div className="side back">
                  <div className="dot dtop dleft"></div>
                  <div className="dot dtop dright"></div>
                  <div className="dot dbottom dleft"></div>
                  <div className="dot dbottom dright"></div>
                  <div className="dot center dleft"></div>
                  <div className="dot center dright"></div>
                </div>
                <div className="side back inner"></div>
                <div className="side cover x"></div>
                <div className="side cover y"></div>
                <div className="side cover z"></div>
              </div>
            </div>
          </script>
          <script id="loading" type="text/template">
            <div className="x-dice-container py-5 m-auto d-flex">
              <div id="dice" className="mx-auto">
                <div className="side front">
                  <div className="dot center bg-danger"></div>
                </div>
                <div className="side front inner"></div>
                <div className="side top">
                  <div className="dot dtop dleft"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side top inner"></div>
                <div className="side right">
                  <div className="dot dtop dleft"></div>
                  <div className="dot center"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side right inner"></div>
                <div className="side left">
                  <div className="dot dtop dleft"></div>
                  <div className="dot dtop dright"></div>
                  <div className="dot dbottom dleft"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side left inner"></div>
                <div className="side bottom">
                  <div className="dot center"></div>
                  <div className="dot dtop dleft"></div>
                  <div className="dot dtop dright"></div>
                  <div className="dot dbottom dleft"></div>
                  <div className="dot dbottom dright"></div>
                </div>
                <div className="side bottom inner"></div>
                <div className="side back">
                  <div className="dot dtop dleft"></div>
                  <div className="dot dtop dright"></div>
                  <div className="dot dbottom dleft"></div>
                  <div className="dot dbottom dright"></div>
                  <div className="dot center dleft"></div>
                  <div className="dot center dright"></div>
                </div>
                <div className="side back inner"></div>
                <div className="side cover x"></div>
                <div className="side cover y"></div>
                <div className="side cover z"></div>
              </div>
            </div>
          </script>
        </div >
        <ToastContainer />
      </>
    );
  }
}

export default App;
